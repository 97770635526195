import React from 'react'
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal'
import * as auth from '../../../services/auth';
import connect from 'react-redux/lib/connect/connect'



class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading:false,
          invalid:false
        }
      }
  handleSubmit = (values) => {
    
    
        this.setState({
            loading: true
          })
        
        var data = {
            email: values.username
          };
          
         
          auth.emailotp(data).then((response)=>{
            if(response.data.status===1)
            {
                  this.props.OTPSent(data.email,response.data.message);
            }
            else
            {
                this.setState({invalid:true,msg:response.data.message,loading:false});
            }
          })
          .catch((error)=>{
            this.setState({
              loading:false
            })
            Modal.error({
              title: 'Network Error',
              content: 'something went wrong..',
            });
          })

  };

  render() {
    return (
      <Form onFinish={this.handleSubmit} className="login-form">
        
        <div style={{fontSize:18,marginTop:20,textAlign:"center",paddingBottom:10}}>Enter the email you signed up with to receive your login code</div>
          {this.state.invalid && <div className="text-center" style={{color:'red',marginBottom:10}}>{this.state.msg}</div>}
          <Form.Item style={{margin:0,padding:0}} name="username" rules={[{ required: true, message: 'Enter Email'},{type:'email', message:'Enter valid email', validateTrigger:'submit' }]}>
            
              <Input style={{margin:0,height:46,fontSize:16,borderEndStartRadius:0,borderEndEndRadius:0,color:'#6c757d'}}
                placeholder="Email Address"
              />
          </Form.Item>
        <Form.Item>
          
           
            <Button htmlType="submit" className="blooms-btn mt-3" style={{width:'100%'}} loading={this.state.loading}>
            Send Code
          </Button>  
          
          
          
        </Form.Item>
       
      </Form>
    );
  }
}




const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      loggedIn: (data) => {
          dispatch({ type: "USER_LOGIN", payload:data });
      }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
