const initialState = {
    isLoggedIn: localStorage.getItem("Id")?true:false,
    Id: localStorage.getItem("Id") || 0,
    fname: localStorage.getItem("fname") || "",
    lname: localStorage.getItem("lname") || "",
    group_id:localStorage.getItem("group_id") || 0,
    email:localStorage.getItem("email") || "",
    coutry_code:localStorage.getItem("coutry_code") || "",
    phone:localStorage.getItem("phone") || "",
    pic:localStorage.getItem("pic") || "",
    authToken:localStorage.getItem("authToken") || ""
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case 'USER_LOGOUT':
        localStorage.clear();
        return {
          ...state,
          isLoggedIn:false
        }
      case 'USER_LOGIN':
          localStorage.setItem("Id",action.payload.id);
          localStorage.setItem("fname",action.payload.fname);
          localStorage.setItem("lname",action.payload.lname);
          localStorage.setItem("group_id",action.payload.group_id);
          localStorage.setItem("email",action.payload.email);
          localStorage.setItem("coutry_code",action.payload.coutry_code);
          localStorage.setItem("phone",action.payload.phone);
          localStorage.setItem("pic",action.payload.pic);
          localStorage.setItem("authToken",action.payload.authToken);
          localStorage.setItem("user_token",action.payload.user_token);
          localStorage.setItem("start_time",action.payload.start_time);
          localStorage.setItem("end_time",action.payload.end_time);
          
        return {
          ...state,
          isLoggedIn: true,
          Id:action.payload.Id,
          fname:action.payload.fname,
          lname:action.payload.lname,
          group_id:action.payload.group_id,
          email:action.payload.email,
          coutry_code:action.payload.coutry_code,
          phone:action.payload.phone,
          pic:action.payload.pic,
          authToken:action.payload.authToken,
        }
  
      default:
        return state
    }
  }