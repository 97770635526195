import React from 'react'
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import Drawer from 'antd/lib/drawer'
import * as API from '../../services/accounts';
import Form from 'antd/lib/form'
import PhoneInput from 'react-phone-input-2'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'



          
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false
    }
    
  }
  componentDidMount()
  {
    
  }
  SubmitData(values)
  {
    var data = {
      phone: values.phone.replace(this.state.countrycode,""),
      country_code:this.state.countrycode,
      user_id:localStorage.getItem("Id")
    };
    this.setState({loading:true})
    API.mobileverify(data).then((res)=>{
      if(res.data.status===1)
      {
        this.setState({message:res.data.message,errmessage:false,IsSent:true,phone:data.phone,country_code:data.country_code,loading:false})
      }
      else
      {
        
        this.setState({loading:false,errmessage:res.data.message})
      }
    }).catch(err=>{
      Modal.error({title:'Something Went Wrong'})
    })
  }
  SubmitData2(values)
  {
    var data = {
      phone: this.state.phone,
      country_code:this.state.country_code,
      code:values.code
    };
    this.setState({loading:true})
    API.mobile_verify_otp(data).then((res)=>{
      if(res.data.status===1)
      {
        
        this.props.onSave(data.phone);
      }
      else
      {
         this.setState({loading:false,errmessage:res.data.message})
      }
    }).catch(err=>{
      Modal.error({title:'Something Went Wrong'})
    })
  }
  render()
  {
    
    return (<Drawer visible={true} placement="right" width="350" title={"Phone Number"} onClose={()=>{this.props.onClose()}}>
      
        {!this.state.IsSent?<Form layout="vertical" onFinish={(e)=>{ this.SubmitData(e); }} initialValues={{phone:'+44'}}>
            <div className="input-block2" style={{flexDirection:'column',justifyContent:'start'}}>
                <div className="label">Mobile Phone</div>
                <div>
                  {this.props.phone}<br />
                  Enter your new phone number below and click send. We'll send you an SMS code to verify your phone.</div>
                  {this.state.errmessage && <div style={{color:'red',marginTop:7,marginBottom:7}}>{this.state.errmessage}</div>}
                <span className="text">

                <Form.Item style={{margin:0,padding:0,width:'100%'}} name="phone" rules={[{ required: true, message: 'Enter Phone'}]}>
                  <PhoneInput 
                  onChange={(e,c)=>{  this.setState({phone:e,countrycode:c.dialCode}) }}
                    placeholder="Mobile Number"
                    
                  />
                </Form.Item>
                  
                </span>
            </div>
            
            <div className="input-block">
              <Button loading={this.state.loading} htmlType="submit" type="primary" style={{width:'100%'}}>Send SMS</Button>
            </div>
            </Form>:
            <Form layout="vertical" onFinish={(e)=>{ this.SubmitData2(e); }}>
            <div className="input-block2" style={{flexDirection:'column',justifyContent:'start'}}>
                <div className="label">Mobile Phone</div>
                <div>
                  Enter your code sent to your number.<br />
                  {this.state.phone}
                  </div>
                  {this.state.errmessage && <div style={{color:'red',marginTop:7,marginBottom:7}}>{this.state.errmessage}</div>}
                <span className="text">

                <Form.Item style={{margin:0,padding:0,width:'100%'}} name="code" rules={[{ required: true, message: 'Enter Phone'}]}>
                  <Input 
                    placeholder="Enter your code..."
                    
                  />
                </Form.Item>
                  
                </span>
            </div>
            
            <div className="input-block">
              <Button loading={this.state.loading} htmlType="submit" type="primary" style={{width:'100%'}}>Verify</Button>
            </div>
            </Form>}
            
      
    </Drawer>);
  }
  
}

export default Index
