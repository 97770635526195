import React from 'react'
import {connect} from 'react-redux'
import $ from 'jquery'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import UploadOutlined from '@ant-design/icons/UploadOutlined'
import _ from 'lodash'
import moment from 'moment'
import {siteUrl} from '../../../services/baseService'
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    value:''
    }
  }
  componentDidUpdate()
  {
      let self = this;
    setTimeout(()=>{ 
        if(self.props.selected_channel!==null)
        {
            
            if(self.props.isMobile===true)
            {
                if($('#chatm')[0]) $('#chatm').scrollTop($('#chatm')[0].scrollHeight) 
            }
            else
            {
                if($('#chat')[0]) $('#chat').scrollTop($('#chat')[0].scrollHeight) 
            }
        }
    },200);
  }
  renderMessage(d)
  {
      let date = "";
      let fdate  =moment(d.createdAt).calendar(null,{
        lastDay : '[Yesterday]',
        sameDay : '[Today]',
        nextDay : '[Tomorrow]',
        lastWeek : '[last] dddd',
        nextWeek : 'dddd',
        sameElse : 'L'
    });
    if(this.fdate!==fdate)
    {
        date=fdate;
        this.fdate=fdate;
    }
      
      let style = {marginRight:'20%',marginLeft:10,backgroundColor:'white',padding:5,borderRadius:20,fontSize:16,paddingRight:20,paddingLeft:20};
      if(d._sender && d._sender.userId===localStorage.getItem('Id'))
        style = {marginLeft:'20%',marginRight:10,backgroundColor:'rgb(78, 72, 226)',padding:5,borderRadius:20,color:'white',fontSize:16,paddingRight:20,paddingLeft:20};
        if(!d._sender)
        {
            style = {backgroundColor:'white',padding:5,borderRadius:20,textAlign:'center',color:'rgb(51, 62, 74)',textTransform:'uppercase',paddingRight:20,paddingLeft:20};
        }
    return [date!==""?<div className="my-2 px-3" style={{color:'#666',margin:'auto',backgroundColor:'white',borderRadius:10}}>{date}</div>:'',<div className={`d-flex p-1 align-items-center justify-content-${!d._sender?'center':d._sender.userId===localStorage.getItem('Id')?'end':'start'}`} style={{marginBottom:1}}>
        
        <div style={style} className="d-flex">
            {d.data!==""?<div>File Uploaded: <br />
                <a className={!d._sender?'center':d._sender.userId===localStorage.getItem('Id')?'white':'black'} rel="noopener noreferrer" target="_blank" href={`${siteUrl}${d.data}`}>{d.message}</a>
            </div>:
            <div style={{flex:1}} dangerouslySetInnerHTML={{__html:d.message.split("\n").join("<br />")}}></div>}
            {d._sender && <div style={{fontSize:13,marginLeft:15}}>{moment(d.createdAt).format("HH:mm")}</div>}
        </div>
    </div>];
  }
  render()
  { 
      
      if(this.props.selected_channel===null)
      return <div style={{backgroundColor:'rgb(244, 245, 246)',height:'calc(100vh - 96px)',flex:1,flexDirection:'column'}} className="d-flex"></div>
      let user = _.find(this.props.selected_channel.members,(a)=>a.userId!==localStorage.getItem("Id"));
    return (<div style={{backgroundColor:'rgb(244, 245, 246)',height:'calc(100vh - 95px)',flex:1,flexDirection:'column'}} className="d-flex">
        {this.props.isMobile && <div className="title_block">
            {user.nickname}
        </div>}
        <div style={{flex:1,overflowY:'auto'}} id={this.props.isMobile?"chatm":"chat"}>
            <div style={{flexDirection:'column'}} className="d-flex justify-content-end">
                {this.props.messages.map((d)=>this.renderMessage(d))}
            </div>
        </div>
        <div style={{padding:20,backgroundColor:'#ccc'}} className="d-flex">
           
           <Button icon={<UploadOutlined />} type="primary" shape="circle" className="mr-3" onClick={()=>{ $('#uploadfilebtn').trigger('click')}} />
           
            <Input.TextArea placeholder="Write something here and click enter." value={this.state.value} onChange={(e)=>{  if(!this.IsEnter) this.setState({value:e.target.value})}} autoSize style={{resize:'none',borderRadius:16}} onPressEnter={(e)=>{ this.IsEnter=false;  if(!e.shiftKey) { this.IsEnter=true; let self=this; let val = e.target.value; setTimeout(()=>{self.props.sendMessage(val); this.IsEnter=false; self.setState({value:''})},200);  } }} />
        </div>
        
    </div>);
  }
}
const mapStateToProps=(state)=>{
    return {
        messages:state.sendbird.messages,
        selected_channel:state.sendbird.selected_channel
    }
}
const mapDispatchToProps = dispatch => {
    return {
        sendMessage: (data) => {
          dispatch({ type: "MESSAGE_SEND", payload:data });
        }
    };
  };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)