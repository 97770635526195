import React from 'react'
import FullCalendar from '@fullcalendar/react'
import Button from 'antd/lib/button'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import moment from 'moment';
import Modal from 'antd/lib/modal'
import * as accounts from '../../services/accounts';
import AvailabilityForm from './components/AvailabilityForm'
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined'
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined'
class Availability extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from:'',
      to:'',
      events:[]
    }
    this.calendarRef = React.createRef();
    
  }
  
  loadData()
  {
         this.setState({loading:true })
            accounts.useravailibilities({from:this.state.from,to:this.state.to}).then((response)=>{
              let events = response.data.map((d)=>({iid:d.id,...d}))
              this.setState({loading:false,events });
            })
            .catch((error)=>{
              this.setState({loading:false})
              Modal.error({
                title: 'Network Error',
                content: 'something went wrong..',
              });
            })
            
  }
  getMonthTitle()
  {
    if(this.state.from !== '')
    {
      if(moment(this.state.from).format("MMM") !== moment(this.state.to).format("MMM"))
      {
        return moment(this.state.from).format("MMM") + " - " + moment(this.state.to).format("MMM");;
      }
      else if(this.props.dayview)
        return moment(this.state.from).format("DD MMM YYYY")

      return moment(this.state.from).format("MMM");
    }
    return '';
  }
  render()
  {
    
    return (<div>
      
          
          <div className="row mb-2">
            <div className="col-lg-12 d-flex align-items-center">
              <span className="calendar-title">{this.getMonthTitle()}</span>
              <Button type="primary" className="ml-2" shape="circle" icon={<ArrowLeftOutlined />} onClick={()=>{this.calendarRef.current.calendar.prev()}} />
              <Button type="primary" className="ml-2" shape="circle" icon={<ArrowRightOutlined />} onClick={()=>{this.calendarRef.current.calendar.next()}} />
            </div>
          </div>
          <FullCalendar defaultView={this.props.dayview?'timeGridDay':'timeGridWeek'} 
          selectable={true}
          selectAllow={(e)=>{
            return e.endStr.substr(0,10)===e.startStr.substr(0,10);
          }}
          
          select={(e)=>{
            var data = {
              id:0,
              avail_date:e.startStr.substr(0,10),
              from_time:e.startStr.substr(11,5),
              to_time:e.endStr.substr(11,5),
              is_repeat:false,
              is_online:false,
              repeats:1
            }
            this.setState({is_avail_form:true,formdata:data,isEdit:false})
          }}
          
          allDaySlot={false} 
          minTime={localStorage.getItem("start_time")} 
          maxTime={localStorage.getItem("end_time")} 
          slotDuration={"00:15:00"} 
          slotLabelInterval={"01:00"} 
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12:false
          }}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
            hour12:false
          }}
          header={null}
          eventClick={(e)=>{
            
            var sdata = e.event._def.extendedProps;
            var data = {
              id:sdata.iid,
              avail_date:sdata.avail_date,
              from_time:sdata.from_time,
              to_time:sdata.to_time,
              is_repeat:false,
              is_online:sdata.is_online==='1',
              repeats:1
            }
            this.setState({formdata:data,isEdit:true,is_avail_form:true});
          }}
          events={(e,c)=>{
            if(this.state.from.toString()!==e.startStr.toString())
            {
              let self = this;
              self.state.from = e.startStr;
              self.state.to = e.endStr;
              this.loadData();
              
              
            }
            c(this.state.events);
        }}
          eventRender={(e)=>{
            
            
           
          }}
          ref={this.calendarRef}
          firstDay={1}
          nowIndicator={false}
          
          plugins={[ timeGridPlugin,interactionPlugin ]} />


         {this.state.is_avail_form===true && <AvailabilityForm onClose={()=>{this.setState({is_avail_form:false})}} saved={()=>{ this.loadData(); this.setState({is_avail_form:false}); }} isEdit={this.state.isEdit} formdata={this.state.formdata} />}



          </div>
          
     );
  }
  
}

export default Availability
