import baseService from './baseService';
import baseService2 from './baseService2';


export function login(credentails){
    return baseService.post('/auth/login_panel',credentails);
}
export function public_login(credentails){
    return baseService.post('auth/public_login',credentails);
}
export function emailotp(credentails){
    return baseService2.post('ajaxs/emailotp',credentails);
}
export function phoneotp(credentails){
    return baseService2.post('API/getotp.php',credentails);
}

