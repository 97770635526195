import React from 'react'
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import Drawer from 'antd/lib/drawer'
import Spin from 'antd/lib/spin'
import moment from 'moment'
import CheckOutlined from '@ant-design/icons/CheckOutlined'
import * as API from '../../services/booking';
import {siteUrl} from '../../services/baseService'
import _ from 'lodash'
import Select from 'antd/lib/select'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import Menu from 'antd/lib/menu'
import Dropdown from 'antd/lib/dropdown'
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      booking:props.booking,
      loading:false
    }
    
  }
  componentDidMount()
  {
    this.LoadData();
  }
  LoadData()
  {
    this.setState({loading:true});
    API.lesson_detail({class_fee_id:this.props.booking.class_id,md:1}).then((res)=>{
      if(res.data.status===1)
      {
        if(res.data.data.subject_id==='0') res.data.data.subject_id=undefined;
        if(res.data.data.course_id==='0') res.data.data.course_id=undefined;
        this.setState({booking:_.extend(this.props.booking,res.data.data),loading:false})
      }
    }).catch((err)=>{
      this.setState({loading:false});
    });
  }
  MarkComplete()
  {
        let item = this.state.booking;
        if(!item.course_id || !item.subject_id)
				{
          Modal.error({title:"Please select the lesson's course and subject before marking it complete"})	
				}
				else if(item.mark_complete===0)
				{
          Modal.error({title:'Lessons must be marked complete within 24 hours of their end time. \n\n Please contact admin and we’ll handle it from there.'})
				}
				else
				{
          this.setState({IsMarkComlete:true})
        }    
  }
  ProcessMarkComplete()
  {
    this.setState({processingMarkComplete:true});
    API.react_mark_complete({token:localStorage.getItem('user_token'),id:this.props.booking.lesson_booking_id}).then((res)=>{
      if(res.data.status===1)
      {
        this.LoadData();
        this.props.onReload();
        this.setState({IsMarkComlete:false});
      }
      else
      {
        Modal.error({title:res.data.message})
        this.setState({processingMarkComplete:false})
      }
    }).catch((err)=>{
      this.setState({processingMarkComplete:false});
    });
  }
  RemoveBooking()
  {
    this.setState({processingRemoveBooking:true});
    API.ajax_react_remove_booking({token:localStorage.getItem('user_token'),id:this.props.booking.lesson_booking_id}).then((res)=>{
      if(res.data.status===1)
      {
        this.props.onDelete();
        this.setState({processingRemoveBooking:false,IsDeleteLesson:false});
      }
      else
      {
        Modal.error({title:res.data.message})
        this.setState({processingRemoveBooking:false,IsDeleteLesson:false})
      }
    }).catch((err)=>{
      this.setState({processingRemoveBooking:false});
    });
  }
  ProcessSendNote()
  {
    this.setState({processingSendNote:true});
    API.ajax_react_send_message_mark_complete({token:localStorage.getItem('user_token'),id:this.props.booking.lesson_booking_id,comment:this.state.SendNote}).then((res)=>{
      if(res.data.status===1)
      {
        this.LoadData();
        this.props.onReload();
        this.setState({IsSendToAdmin:false});
      }
      else
      {
        Modal.error({title:res.data.message})
        this.setState({processingSendNote:false})
      }
    }).catch((err)=>{
      this.setState({processingSendNote:false});
    });
  }
  render()
  {
    let item = this.state.booking;
    let color = "#00d981";
		if(item.status_name==="Confirmed")
			color = "#7f7f7f";
		if(item.status_name==="Complete")
			color = "#00d981";
		if(item.status_name==="Processing Paused")
			color = "#00d981";
    
    let isMarkCompleteButton = localStorage.getItem("group_id")==='4' && item.booking_status === '1';

    const menu = (
      <Menu onClick={()=>{ }}>
        {isMarkCompleteButton && <Menu.Item key="1" onClick={()=>this.props.onEdit(item)}>
          Edit Lesson
        </Menu.Item>}
        {isMarkCompleteButton && <Menu.Item key="2" onClick={()=>this.MarkComplete()}>
          Mark Complete
        </Menu.Item>}
        {isMarkCompleteButton && <Menu.Item key="3" onClick={()=>{
          this.setState({IsDeleteLesson:true});
        }}>
          Delete Lesson
        </Menu.Item>}
        
      </Menu>
    );
    
  return (<Drawer visible={true} placement="right" width="350" onClose={()=>{this.props.onClose()}}>
      {this.state.IsDeleteLesson && <Modal visible={true} title="Delete Confirmation" footer={null}>
          <div>
            Do you want to delete this booking ?
          </div>
          <div className="d-flex justify-content-end mt-2">
            <Button type="danger" className="mr-2" style={{backgroundColor:'red'}} loading={this.state.processingRemoveBooking} onClick={()=>this.RemoveBooking()}>Delete</Button>
            <Button onClick={()=>this.setState({IsDeleteLesson:false})}>Cancel</Button>
          </div>
        </Modal>}
      {this.state.IsMarkComlete && <Drawer title="Mark Lesson Complete" width="350"  visible={true} onClose={()=>this.setState({IsMarkComlete:false})}>
        <div className="input-block">
            <div className="label">Student</div>
            <span className="text">
                {item.title}
            </span>
        </div>
        <div className="input-block">
            <div className="label">Date</div>
            <span className="text">
                {moment(item.start).format("ddd MMM D")}
            </span>
        </div>
        <div className="input-block">
            <div className="label">Time</div>
            <span className="text">
                {moment(item.start).format("HH:mm")} - {moment(item.end).format("HH:mm")}
            </span>
        </div>
        <div className="input-block">
            <div className="label">Venue</div>
            <span className="text">
                {item.ServiceName==='Online'?"Online":"At the centre"}
            </span>
        </div>
        <div className="input-block">
            <div className="label">Fee</div>
            <span className="text">
            &pound;{item.tutor_gross_fee}
            </span>
        </div>
        <div className="m-4">
          
          <div>
            Did this lesson go entirely as above, for its full duration, at the agreed venue? <br /><br />
            Careful! If you click Yes, we will charge the client, so please be <b>100% sure</b>, if you're not, for any reason, click "No / Not Sure" to send a note to admin.
          </div>
          <div className="my-2 d-flex">
            <div style={{flex:1}}>
              <Button style={{width:'100%',marginRight:5}} type="primary" loading={this.state.processingMarkComplete} onClick={()=>{this.ProcessMarkComplete()}}>Yes</Button>
            </div>
            <div style={{flex:1}}>
              <Button style={{width:'100%',marginLeft:5}} type="default" onClick={()=>{this.setState({IsSendToAdmin:true,IsMarkComlete:false,SendNote:''})}}>No / Not sure</Button>
              <div style={{fontSize:10,fontWeight:'bold',textAlign:'center'}}>(continue to final step)</div>
            </div>
          </div>
          </div>
        </Drawer>}
        {this.state.IsSendToAdmin && <Drawer title="Send To Admin" width="350" centered visible={true} footer={null} onClose={()=>this.setState({IsSendToAdmin:false})}>
        <div className="input-block">
            <div className="label">Student</div>
            <span className="text">
                {item.title}
            </span>
        </div>
        <div className="input-block">
            <div className="label">Date</div>
            <span className="text">
                {moment(item.start).format("ddd MMM D")}
            </span>
        </div>
        <div className="input-block">
            <div className="label">Time</div>
            <span className="text">
                {moment(item.start).format("HH:mm")} - {moment(item.end).format("HH:mm")}
            </span>
        </div>
        <div className="input-block">
            <div className="label">Venue</div>
            <span className="text">
                {item.ServiceName==='Online'?"Online":"At the centre"}
            </span>
        </div>
        <div className="input-block">
            <div className="label">Fee</div>
            <span className="text">
            &pound;{item.tutor_gross_fee}
            </span>
        </div>
        <div className="m-4">
          <div>
            <b>Did this lesson go entirely to plan, for its full duration, at the agreed venue?</b> <br /><br />
            <b>Your Answer: No / No I'm not sure.</b><br /><br />
            Ok. Please edit the lesson or provide a note below sand send to admin. This will pause processing. 
          </div>
          <div className="mt-2">
            <Input.TextArea value={this.state.SendNote} onChange={(e)=>this.setState({SendNote:e.target.value})} placeholder="Insert note here. E.g. We finished 15 min early" />
          </div>
          <div className="my-2 d-flex">
            <div style={{flex:1}}>
              <Button style={{width:'100%',marginRight:5}} disabled={this.state.SendNote===''} type="primary" loading={this.state.processingSendNote} onClick={()=>{this.ProcessSendNote()}}>Send Note</Button>
            </div>
            <div style={{flex:1}}>
              <Button style={{width:'100%',marginLeft:5}} type="default" onClick={()=>this.setState({IsSendToAdmin:false})}>Cancel</Button>
            </div>
          </div>
          </div>
        </Drawer>}
      <Spin spinning={this.state.loading}>
              <div className="input-block">
                
                    <div className="label"><LeftOutlined style={{fontSize:20}} onClick={()=>{ this.props.onClose(); }} /></div>
                    <span className="text">
                      {isMarkCompleteButton && <Dropdown.Button overlay={menu}></Dropdown.Button>}
                    </span>  
                  
                
            </div> 
            <div className="input-block">
                    <div className="label">{item.title}</div>
                    <span className="text">
                      <img width={100} src={`${siteUrl}${item.extra_thumbnail}`} alt={item.title} />
                    </span>  
            </div>
            <div className="input-block">
                <div className="label">Date</div>
                <span className="text">
                    {moment(item.start).format("ddd MMM D")}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Time</div>
                <span className="text">
                    {moment(item.start).format("HH:mm")} - {moment(item.end).format("HH:mm")}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Venue</div>
                <span className="text">
                    {item.ServiceName==='Online'?"Online":"At the centre"}
                </span>
            </div>
            {item.ServiceName==='Online' && <div className="input-block">
                <div className="label">Link</div>
                <span className="text">
                <a href={item.room_link} target="_blank" rel="noopener noreferrer">{item.room_link}</a>
                </span>
            </div>}
            <div className="input-block">
                <div className="label">Status</div>
                <span className="text" style={{color}}>
                  {item.status_name} <CheckOutlined style={{color:item.status_name==='Confirmed'?'green':'grey'}} />
                </span>
            </div>
            <div className="input-block">
                <div className="label">Course</div>
                <span className="text">
                  <Select onChange={(e)=>{ item.course_id=e; item.subject_id=undefined; API.lesson_update_course({lesson_booking_id:item.lesson_booking_id, course_id:e || 0}); this.setState({set:true}) }} value={item.course_id} size="small" style={{width:220}} placeholder="Select...">
                    {item.courses && item.courses.map((d)=><Select.Option value={d.id}>{d.name}</Select.Option>)}
                  </Select>
                </span>
            </div>
            <div className="input-block">
                <div className="label">Module</div>
                <span className="text">
                    <Select onChange={(e)=>{ item.subject_id=e; API.lesson_update_subject({lesson_booking_id:item.lesson_booking_id, subject_id:e || 0}); this.setState({set:true}) }} value={item.subject_id} size="small" style={{width:220}} placeholder="Select...">
                      {item.course_id && item.subjects && _.filter(item.subjects,{course_id:item.course_id}).map((d)=><Select.Option value={d.subject_id}>{d.name}</Select.Option>)}
                    </Select>
                </span>
            </div>
            <div className="input-block">
                <div className="label">Fee</div>
                <span className="text">
                &pound;{item.tutor_gross_fee}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Cancellation Period</div>
                <span className="text">
                  {item.cancel_policy}
                </span>
            </div>
            
            
            </Spin>
    </Drawer>);
  }
  
}

export default Index
