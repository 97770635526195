import axios from 'axios';

//export const baseUrl2 = 'https://dev.lawtutors.co.uk/';
export const baseUrl2 = 'https://lawtutors.co.uk/';
//export const baseUrl = 'http://192.168.29.179/manager/index.php/';


const baseService2 = axios.create({
    baseURL: baseUrl2,
    rejectUnauthorized: false,
    defaults: {headers: {common: {Authorization:  "Basic YW1pdDpBbWl0MTM1Iw=="}}}
});

baseService2.defaults.headers.common['Authorization'] = "Basic YW1pdDpBbWl0MTM1Iw==";

baseService2.interceptors.response.use((config) => {
    return config;
}, (error) => {
   
    if (error.response.status === 401) {
       // baseService.removeAuthToken();
    }
    return window.Promise.reject(error);
});

export default baseService2;