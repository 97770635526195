import React from 'react'
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal'
import Button from 'antd/lib/button';
import * as auth from '../../../services/auth';
import connect from 'react-redux/lib/connect/connect'



class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading:false,
          invalid:false
        }
      }
  handleSubmit = (values) => {
    
    
        this.setState({
            loading: true
          })
        
        var data = {
            email: this.props.Email,
            type: this.props.Type,
            code: values.code,
            phone:this.props.phone,
            country_code:this.props.country_code
          };
          
         
          auth.public_login(data).then((response)=>{


            if(response.data.status===1)
            {
                  this.props.LoggedIn(response.data);
            }
            else
            {

                this.setState({invalid:true,msg:response.data.msg,loading:false});
            }
              
          
            
          })
          .catch((error)=>{
            this.setState({
              loading:false
            })
            Modal.error({
              title: 'Network Error',
              content: 'something went wrong..',
            });
          })

  };

  render() {
    return (
      <Form onFinish={this.handleSubmit} className="login-form">
        
        <div style={{fontSize:18,marginTop:20,textAlign:"center",paddingBottom:10}}>{this.props.message}</div>
          {this.state.invalid && <div className="text-center" style={{color:'red',marginBottom:10}}>{this.state.msg}</div>}
          <Form.Item style={{margin:0,padding:0}} name="code" rules={[{ required: true, message: 'Enter code'}]}>
            
              <Input style={{margin:0,height:46,fontSize:16,borderEndStartRadius:0,borderEndEndRadius:0,color:'#6c757d'}}
                placeholder="Enter your code..."
              />
          </Form.Item>
        <Form.Item>
          
        <Button htmlType="submit" className="blooms-btn mt-3" style={{width:'100%'}} loading={this.state.loading}>
            Verify OTP
          </Button>  
            
          
          
          
        </Form.Item>
        <div className="mt-5 text-center">© 2019-2020</div>
      </Form>
    );
  }
}




const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      loggedIn: (data) => {
          dispatch({ type: "USER_LOGIN", payload:data });
      }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
