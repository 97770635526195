import React from 'react'
import Channels from './components/Channels'
import Messages from './components/Messages'
import Drawer from 'antd/lib/drawer'
import SharedFiles from './components/Files'
import FutureLessons from './components/FutureLessons'
import {connect} from 'react-redux'
import Tabs from 'antd/lib/tabs'
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
    }
    
  }
  
  loadData()
  {
         
            
  }
  render()
  {
    
    return (<div className="d-flex" style={{width:'100%'}}>
      <div style={{width:350, borderRight:'1px solid #e5e5e5'}} className="d-none d-sm-none d-md-block">
          <div className="title_block">My {localStorage.getItem('group_id')==='3'?'Tutors':'Students'}</div>
        <div style={{overflowY:'auto',height:'calc(100vh - 96px)'}}>
          <Channels />
        </div>
      </div>
      <div style={{width:'100%'}} className="d-block d-sm-block d-md-none">
        <Channels />
      </div>
      <div style={{flex:1}} className="d-none d-sm-none d-md-block">
        <div className="d-flex">
          <div style={{flex:1,flexDirection:'column'}} className="d-flex">
            <div className="title_block">Messages</div>
            <div style={{flex:1}}><Messages /></div>
          </div>
          <div style={{width:350,backgroundColor:'white',flexDirection:'column', borderLeft:'1px solid #e5e5e5'}} className="d-flex">
            <div style={{flex:1}}>
              {this.props.selected_channel && <SharedFiles />}
            </div>
            <div style={{flex:1}}>
            {this.props.selected_channel && <FutureLessons />}
            </div>
            
          </div>
        </div>
      </div>
      
      {this.props.selected_channel && <Drawer onClose={()=>{ this.props.exitChannel()}} width="350" visible={true} className="d-block d-sm-block d-md-none">
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Chats" key="1">
              <Messages isMobile={true} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Files" key="2">
              <SharedFiles />
            </Tabs.TabPane>
          </Tabs>
        </Drawer>}
      
    </div>);
  }
  
}

const mapStateToProps=(state)=>{
  return {
      selected_channel:state.sendbird.selected_channel
  }
}
const mapDispatchToProps = dispatch => {
  return {
    exitChannel: (data) => {
      dispatch({ type: "CHANNEL_SELECT", payload:null });
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
