import React from 'react'
import {connect} from 'react-redux'
import {siteUrl} from '../../../services/baseService'
import _ from 'lodash'
import moment from 'moment'
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
    }
  }
  loadData()
  {
            
  }
  
  renderChannel(d)
  {
      console.log(d);
    let user = _.find(d.members,(a)=>a.userId!==localStorage.getItem("Id"));
    if(!user)
        return <div />;
    return <div className="d-flex p-2 align-items-center" style={{backgroundColor:'white',marginBottom:1,cursor:'pointer'}} onClick={()=>{this.props.selectChannel(d)}}>
    <div style={{position:'relative'}}>
        <div style={{height:80}}>
        <img alt="avatar" src={`${siteUrl}${user.profileUrl}`} width="80" style={{borderRadius:40}} />
        </div>
        {user.connectionStatus==="online" &&<div style={{width:14,height:14,backgroundColor:'#87d068',position:'absolute',top:60,left:62,zIndex:12,borderRadius:7,border:'1px solid white'}}>

        </div>}
    </div>
    <div style={{flex:1,paddingLeft:10}}>
        <div className="d-flex">
            <div style={{flex:1}}>
                <div style={{fontSize:17}}>{user.nickname}</div>
                <div style={{fontSize:13,color:'#aaa',marginTop:5,width:200}} className="overflow_text">{d.lastMessage?d.lastMessage.message:<span>&nbsp;</span>}</div>
            </div>
            <div className="d-flex align-items-end" style={{flexDirection:'column'}}>
                {d.lastMessage && <div style={{fontSize:13,color:'#ccc',marginBottom:10}}>
                    {moment(d.lastMessage.createdAt).format("HH:mm")}
                </div>}
                {d.unreadMessageCount>0 && <div style={{height:20,width:20,backgroundColor:'#87d068',color:'white',lineHeight:'18px',textAlign:'center',borderRadius:10,fontWeight:500}}>
                    {d.unreadMessageCount}
                </div>}
            </div>
        </div>
    </div>

    </div>;
  }
  render()
  { 
    return (<div>
        {this.props.channels.map((d)=>this.renderChannel(d))}
    </div>);
  }
}

const mapStateToProps=(state)=>{
    return {
        channels:state.sendbird.channels
    }
}
const mapDispatchToProps = dispatch => {
    return {
        selectChannel: (data) => {
          dispatch({ type: "CHANNEL_SELECT", payload:data });
        }
    };
  };
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)