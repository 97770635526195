import React from 'react'
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import Modal from 'antd/lib/modal'
import * as accounts from '../../services/accounts';
import API from '../../promises/accounts';
import SideMenu from '../app/components/SideMenu';
import TutorCourseSubject from '../components/TutorCourseSubject'
import Button  from 'antd/lib/button';
import { connect } from 'react-redux'
import store from '../../store'
const state = store.getState();
class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit:false,
      IsAdd:false
    }
    
  }
  componentDidMount()
  {
    this.loadData();
    
  }
  loadData()
  {
    this.setState({loading:true })
    API.getuser().then((response)=>{
      let self=this;
      self.state.subjects = response.data.subjects;
      self.state.courses = response.data.courses;
      self.state.departments = response.data.departments;
      self.state.tutor_departments = response.data.tutor_departments;
     
      
      this.setState({set:true});
    })
    .catch((error)=>{
      this.setState({loading:false})
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
  }
  saveData()
  {
    this.setState({saving:true});
    let data = {tutor_departments:this.state.tutor_departments};
    accounts.update_tutor_teach(data).then((response)=>{
      state.promise.userdata.data.tutor_departments=this.state.tutor_departments;
      this.setState({isEdit:false,saving:false});
    })
    .catch((error)=>{
      this.setState({saving:false})
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
    
  }
  render()
  {
    
    return (<div className="container mt-5 mb-5">
      
      <div className="row">
          <div className="col-md-3">
           <SideMenu menu="4" title="What I Teach" />
          </div>
          <div className="col-md-9">
          <div className="input-block">
          {this.state.tutor_departments  && <TutorCourseSubject 
          onChange={(val)=>{ this.setState({tutor_departments:val}) }}
          departments={this.state.departments} courses={this.state.courses} subjects={this.state.subjects} tutor_departments={this.state.tutor_departments}  />}
          </div>
          <div className="input-block">
              <Button type="primary" loading={this.state.saving} onClick={()=>this.saveData()}>Save</Button>
          </div>
          </div>
          
      </div>
    </div>);
  }
  
}
const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount)
