import baseService from './baseService';
import baseService2 from './baseService2';
export function lesson_detail(data){
    return baseService.post('/booking/lesson_detail',data);
}
export function lesson_update_subject(data){
    return baseService.post('/booking/lesson_update_subject',data);
}
export function lesson_update_course(data){
    return baseService.post('/booking/lesson_update_course',data);
}
export function tutor_booking_list(){
    return baseService.post('/booking/tutor_booking_list',{});
}
export function ajax_react_user_confirm(data){
    return baseService2.post('/AppAPI/ajax_react_user_confirm',data);
}
export function ajax_react_user_reconfirm(data){
    return baseService2.post('/AppAPI/ajax_react_user_reconfirm',data);
}
export function react_mark_complete(data){
    return baseService2.post('/AppAPI/react_mark_complete',data);
}
export function ajax_react_send_message_mark_complete(data){
    return baseService2.post('/AppAPI/ajax_react_send_message_mark_complete',data);
}
export function ajax_react_remove_booking(data){
    return baseService2.post('/AppAPI/ajax_react_remove_booking',data);
}