import React from 'react'
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import Modal from 'antd/lib/modal'
import * as accounts from '../../services/accounts';
import API from '../../promises/accounts';
import SideMenu from '../app/components/SideMenu';
import Select from 'antd/lib/select'
import { connect } from 'react-redux'
import store from '../../store'
const state = store.getState();
class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit:false,
    }
    
  }
  componentDidMount()
  {
    this.loadData();
    
  }
  loadData()
  {
    this.setState({loading:true })
    API.getuser().then((response)=>{
      this.setState({
        lesson_confirmed_alert:response.data.users.lesson_confirmed_alert,
        lesson_1hr_alert:response.data.users.lesson_1hr_alert,
        lesson_25hr_alert:response.data.users.lesson_25hr_alert,
        booking_processed_alert:response.data.users.booking_processed_alert
      
      });

    })
    .catch((error)=>{
      this.setState({loading:false})
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
  }
  updateField(field,value)
  {
    let self = this;
    self.state[field]=value;
    this.setState({saving:true});
    let data = {field,value};
    
    accounts.update_msg(data).then((response)=>{
      state.promise.userdata.data.users[field]=value;
      this.setState({saving:false});
    })
    .catch((error)=>{
      this.setState({saving:false})
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
    
  }
  render()
  {
    
    return (<div className="container mt-5 mb-5">
      <div className="row">
          <div className="col-md-3">
           <SideMenu menu="6" title="Messages" />
          </div>
          <div className="col-md-9">
            <div className="input-block2">
                <div className="label" style={{minWidth:120}}>NEW LESSON BOOKED</div>
                <span className="text">
                  <Select style={{width:'100%',maxWidth:200}} value={this.state.lesson_confirmed_alert} onChange={(e)=>{this.updateField('lesson_confirmed_alert',e)}}>
                    <Select.Option value="0">Email</Select.Option>
                    <Select.Option value="1">SMS</Select.Option>
                    <Select.Option value="2">Email & SMS</Select.Option>
                  </Select>
                </span>
            </div>
            <div className="input-block2">
                <div className="label" style={{minWidth:120}}>24 HOUR LESSON REMINDER</div>
                <span className="text">
                  <Select style={{width:'100%',maxWidth:200}} value={this.state.lesson_25hr_alert} onChange={(e)=>{this.updateField('lesson_25hr_alert',e)}}>
                    <Select.Option value="3">No Alert</Select.Option>
                    <Select.Option value="0">Email</Select.Option>
                    <Select.Option value="1">SMS</Select.Option>
                    <Select.Option value="2">Email & SMS</Select.Option>
                  </Select>
                </span>
            </div>
            <div className="input-block2">
                <div className="label" style={{minWidth:120}}>1 HOUR LESSON REMINDER</div>
                <span className="text">
                  <Select style={{width:'100%',maxWidth:200}} value={this.state.lesson_1hr_alert} onChange={(e)=>{this.updateField('lesson_1hr_alert',e)}}>
                    <Select.Option value="3">No Alert</Select.Option>
                    <Select.Option value="0">Email</Select.Option>
                    <Select.Option value="1">SMS</Select.Option>
                    <Select.Option value="2">Email & SMS</Select.Option>
                  </Select>
                </span>
            </div>
            {this.props.auth.group_id==='4' && <div className="input-block2">
                <div className="label" style={{minWidth:120}}>LESSON PROCESSED</div>
                <span className="text">
                  <Select style={{width:'100%',maxWidth:200}} value={this.state.booking_processed_alert} onChange={(e)=>{this.updateField('booking_processed_alert',e)}}>
                  <Select.Option value="3">No Alert</Select.Option>
                    <Select.Option value="0">Email</Select.Option>
                    <Select.Option value="1">SMS</Select.Option>
                    <Select.Option value="2">Email & SMS</Select.Option>
                  </Select>
                </span>
            </div>}
          </div>
      </div>
      
      
    </div>);
  }
  
}
const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount)
