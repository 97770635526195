import SendBird from 'sendbird'
var sb = new SendBird({appId: '3902BA87-5196-4C5F-B00C-30523304C660'});
export function connect(){
    
    let promise = new Promise((resolve, reject) => {   
        sb.connect('1593',(user,error)=>{
            if(error)
                reject(error)
            else
                resolve(user);
        })
      })
      return promise;
}
export function channelList(){
    let promise = new Promise((resolve, reject) => {   
        sb.connect(localStorage.getItem("Id"),(user,error)=>{
            var channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
            channelListQuery.includeEmpty = true;
            channelListQuery.order = 'latest_last_message'; // 'chronological', 'latest_last_message', 'channel_name_alphabetical', and 'metadata_value_alphabetical'
            channelListQuery.limit = 100;    // The value of pagination limit could be set up to 100.
            if (channelListQuery.hasNext) {
                channelListQuery.next(function(groupChannels, error) {
                    if (error) {
                        reject(error)
                    }
                    resolve(groupChannels)
                });
            }
        })
      })
      return promise;
    
    
}
export function messageList(groupChannel){
    
    
    let promise = new Promise((resolve, reject) => {   
        if(groupChannel===null)
            resolve([]);
            var prevMessageListQuery = groupChannel.createPreviousMessageListQuery();
            prevMessageListQuery.limit = 100;
            prevMessageListQuery.reverse = false;
            prevMessageListQuery.includeMetaArray = true;   // Retrieve a list of messages along with their metaarrays.
            prevMessageListQuery.includeReaction = true;    // Retrieve a list of messages along with their reactions.
            
            prevMessageListQuery.load((messages, error) =>{
                if (error) {
                    reject(error)
                }
                groupChannel.markAsRead();
                resolve(messages);
            });
        
        })
      return promise;
    
    
}
export function sendMessage(groupChannel,msg){
    
    
    let promise = new Promise((resolve, reject) => {   
        if(groupChannel===null)
            resolve([]);
        const params = new sb.UserMessageParams();
        params.message = msg;
        params.data = '';
        params.mentionType = 'channel';                       
        params.pushNotificationDeliveryOption = 'default';  

        groupChannel.sendUserMessage(params, function(message, error) {
            if (error) {
                reject(error)
            }
            resolve(message);
        });
        })
      return promise;
    
    
}
export function getSB()
{
    return sb;
}