import React from 'react'
import Drawer from 'antd/lib/drawer'
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
 
  render()
  {
    let item=this.props.detail;
    
  return (<Drawer visible={true} placement="right" width="350" onClose={()=>{this.props.onClose()}}>
           <div className="input-block">
                <div className="label">Date</div>
                <span className="text">
                  {item.lesson_date}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Time</div>
                <span className="text">
                  {item.lesson_time}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Student</div>
                <span className="text">
                  {item.student}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Category</div>
                <span className="text">
                  {item.category}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Your Net Fee</div>
                <span className="text">
                  &pound;{item.tutor_fee}
                </span>
                
            </div>
            <div className="input-block">
            <div>(Teaching Fee: &pound;{item.tutor_full_fee}, Room Fee: &pound;{item.tutor_room_fee})</div>
            </div>
            
                        
            
            

    </Drawer>);
  }
  
}

export default Index
