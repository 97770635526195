import React, {Component} from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Menu1 from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import {siteUrl} from '../../../services/baseService'
import $ from 'jquery'
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageloading:false
    }
    
  }
  
  
    componentDidMount() {
      
      
    }
    
	componentWillReceiveProps(nextProps) {
    
	}
   
  render() {
    const DropdownMenu = (
      <Menu1>
        {this.props.auth.group_id==='4' && <Menu1.Item>
          <Link to="/my_account" className="right-dropdown-link">
            Account Settings
          </Link>
        </Menu1.Item>}
        
        {this.props.auth.group_id==='4' && <Menu1.Divider />}
        <Menu1.Item>
          <Link  className="right-dropdown-link" to="/logout">
            Sign Out
          </Link>
        </Menu1.Item>
      </Menu1>
    );      
    
    return (
        <div style={{paddingTop:55}}>
         
        <Navbar bg="light" fixed="top" expand="lg" style={{paddingTop:0,paddingBottom:0}}>
            
        <Navbar.Brand style={{paddingTop:0,paddingBottom:0,paddingLeft:15}}><Link to="/"><img alt="logo" src="/img/logo.svg" width="142" style={{marginTop:14,marginBottom:14}} /></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        
        <Navbar.Collapse id="basic-navbar-nav" onClick={()=>{ if(window.screen.availWidth<600) $('.navbar-toggler').trigger('click') }}>
          {this.props.auth.isLoggedIn===true?<Nav className="mr-auto">
            <Link to="/" className={"nav-link"}>Calendar</Link>
            {this.props.auth.group_id==='3' && <Link to="/my_account" className={"nav-link"}>Account</Link>}
            {this.props.auth.group_id==='4' && <Link to="/income" className={"nav-link"}>Income</Link>}
            <Link to="/messages" className={"nav-link"}>Messages</Link>
          </Nav>:''}
          <Dropdown overlay={DropdownMenu}>
            <img alt="profile" src={`${siteUrl}img/${this.props.auth.pic===null?'fmno-image.png':'upload/'+this.props.auth.pic}`} height="35" width="35" style={{marginRight:10}} />
          </Dropdown>
        </Navbar.Collapse>
      </Navbar>
     </div>
    )

  }
}


const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu)