import React from 'react'
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import Modal from 'antd/lib/modal'
import * as accounts from '../../services/accounts';
import API from '../../promises/accounts';
import SideMenu from '../app/components/SideMenu';
import Button  from 'antd/lib/button';
import Input from 'antd/lib/input'
import { connect } from 'react-redux'
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined'
import TutorQualificationForm from './components/TutorQualificationForm'
import _ from 'lodash'
import store from '../../store'
const state = store.getState();
class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit:false,
      selectedQualification:{}
    }
    
  }
  componentDidMount()
  {
    this.loadData();
    
  }
  loadData()
  {
         this.setState({loading:true })
         API.getuser().then((response)=>{
          let self=this;
          self.state.qualification_course = response.data.qualification_course;
          self.state.qualification_institution = response.data.qualification_institution;
          self.state.qualification_subject = response.data.qualification_subject;
          self.state.qualification_grade = response.data.qualification_grade;
          this.setState({loading:false,
            public_desc:response.data.users.public_desc,
            prefered_name:response.data.users.prefered_name,
            qualifications:response.data.qualifications,
            })
          })
          .catch((error)=>{
            this.setState({loading:false})
            Modal.error({
              title: 'Network Error',
              content: 'something went wrong..',
            });
          })
            
  }
  saveData()
  {
    this.setState({saving:true});
    let data = {
      fname:this.state.firstname,
        public_desc:this.state.public_desc,
        prefered_name:this.state.prefered_name
    };
    accounts.update_public_pro(data).then((response)=>{
      state.promise.userdata.data.users.public_desc=this.state.public_desc;
      state.promise.userdata.data.users.prefered_name=this.state.prefered_name;
       this.setState({isEdit:false,saving:false});
    })
    .catch((error)=>{
      this.setState({saving:false})
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
    
  }
  DeleteQualification(selectedQualification)
  {
    this.setState({selectedQualification});
    let data = {id:selectedQualification.id};
    accounts.delete_qualification(data).then((response)=>{
      let self = this;
      self.state.qualifications = _.filter(self.state.qualifications,(a)=>a.id!==data.id);
      state.promise.userdata.data.qualifications=this.state.qualifications;
      this.setState({selectedQualification:{}});
    })
    .catch((error)=>{
      this.setState({saving:false})
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
  }
  AddQualification()
  {
    this.setState({IsAdd:true})
  }
  render()
  {
    
    return (<div className="container mt-5 mb-5">
      <div className="row">
      {this.state.IsAdd && <TutorQualificationForm 
        onCancel={()=>this.setState({IsAdd:false})}
        onAdd={(d)=>{
          let self = this;
          self.state.qualifications.push(d);
          state.promise.userdata.data.qualifications=this.state.qualifications;
          this.setState({IsAdd:false})
        }}
        qualification_course = {this.state.qualification_course}
        qualification_institution = {this.state.qualification_institution}
        qualification_subject	 = {this.state.qualification_subject}
        qualification_grade = {this.state.qualification_grade}
      />}
          <div className="col-md-3">
           <SideMenu menu="3" title="Public Profile" />
          </div>
          <div className="col-md-9">
            <div className="input-block2">
                <div className="label" style={{minWidth:120}}>DISPLAY NAME (NO SURNAMES PLEASE)</div>
                {this.state.isEdit!==true?<span className="text">
                  {this.state.prefered_name}&nbsp;
                </span>:
                <span className="text"><Input value={this.state.prefered_name} onChange={(e)=>{this.setState({prefered_name:e.target.value})}} style={{width:'100%'}} /></span>}
            </div>
            <div className="input-block2">
                <div className="label" style={{minWidth:120}}>PUBLIC DESCRIPTION</div>
                {this.state.isEdit!==true?<span className="text">
                  {this.state.public_desc}&nbsp;
                </span>:
                <span className="text"><Input.TextArea autoSize value={this.state.public_desc} onChange={(e)=>{this.setState({public_desc:e.target.value})}} style={{width:'100%'}} /></span>}
            </div>
            
            <div className="input-block">
              {this.state.isEdit?<div><Button type="primary" loading={this.state.saving} onClick={()=>this.saveData()}>Save</Button>
              <Button onClick={()=>this.setState({isEdit:false})} className="ml-2">Cancel</Button>
              </div>:
              <Button type="primary" onClick={()=>this.setState({isEdit:true})}>Edit</Button>}
            </div>
            <div className="input-block2 mt-3">
                <div className="label" style={{minWidth:120}}>Qualifications <Button onClick={()=>this.AddQualification()} icon={<PlusCircleOutlined  />} type="link"></Button></div>
                {this.state.qualifications && this.state.qualifications.map((d)=><span className="text d-flex align-items-center">
                  {d.course} ({d.subject}), {d.institution} ({d.grade}) <Button onClick={()=>this.DeleteQualification(d)} icon={<CloseCircleOutlined  />} loading={this.state.selectedQualification.id===d.id} type="link"></Button> 
                </span>)}
            </div>
          </div>
      </div>
      
      
    </div>);
  }
  
}
const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount)
