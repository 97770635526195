import React from 'react'
import FullCalendar from '@fullcalendar/react'
import Button from 'antd/lib/button'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import moment from 'moment';
import Modal from 'antd/lib/modal'
import * as accounts from '../../services/accounts';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined'
import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined'
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import BookingDetail from '../components/BookingDetail'
import NewBooking from '../components/NewBooking'
import EditBooking from '../components/EditBooking'
import {siteUrl} from '../../services/baseService'
import Spin from 'antd/lib/spin'
import $ from 'jquery'
import _ from 'lodash'


class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from:'',
      to:'',
      view:'timeGridWeek',
      events:[]
    }
    this.calendarRef = React.createRef();
  }
  componentDidMount()
  {
    this.loadData();
  }
  getMonthTitle()
  {
    if(this.state.from !== '')
    {
      if(this.state.view === "dayGridMonth")
      {
        return moment(this.state.from).clone().add(7, 'days').format("MMM");  
      }
      if(moment(this.state.from).format("MMM") !== moment(this.state.to).format("MMM"))
      {
        return moment(this.state.from).format("MMM") + " - " + moment(this.state.to).format("MMM");;
      }
      return moment(this.state.from).format("MMM");
    }
    return '';
  }
  loadData()
  {
         this.setState({loading:true })
            accounts.getevents({from:this.state.from,to:this.state.to}).then((response)=>{
              response.data.forEach(element => {
                element.className += ' ' + element.className1;
              });
              let startDate= moment(new Date()).subtract(2,"months");
              let endDate = moment(startDate.toDate()).add(4,"months");
              let calendarArray = [];
              while(startDate.diff(endDate,"day")<0)
              {
                calendarArray.push({date:startDate});
                startDate=moment(startDate.toDate()).add(1,"day");
              }
              
              setTimeout(()=>{ 
                if($('#date_'+moment(new Date()).format("YYYY-MM-DD")).offset()) $('#mobilediv').scrollTop($('#date_'+moment(new Date()).format("YYYY-MM-DD")).offset().top-60);
                
              },200);
              this.setState({loading:false,events:response.data,calendarArray })
            })
            .catch((error)=>{
              this.setState({loading:false})
              Modal.error({
                title: 'Network Error',
                content: 'something went wrong..',
              });
            })
            
  }
  renderEvents(d)
  {
    let events = _.filter(this.state.events,(a)=>moment(a.start).format("YYYY-MM-DD")===d.date.format("YYYY-MM-DD"));
    return <div>
      
    {events.map((item)=><div onClick={()=>{ this.setState({selectedBooking:item}) }} className="d-flex p-2 m-2" style={{backgroundColor:'#eff0f4',borderRadius:5}}>
    <div className="mr-2">
      <img alt="avatar" src={`${siteUrl}img/upload/${item.pic}`} width="50" style={{borderRadius:25}} />
    </div>  
    <div style={{flex:1}}>
      <div style={{color:'#000000',fontSize:14,letterSpacing: 0.2}}>{moment(item.start).format("HH:mm")} - {moment(item.end).format("HH:mm")}</div>
      <div style={{fontSize:14,color:'#979ca0',letterSpacing: 0.2}}>{item.title} | {item.service_type}</div>
    </div>
  </div>)}
    {events.length===0 && <div className="d-flex p-2 m-2" style={{backgroundColor:'#eff0f4',borderRadius:5}}><div style={{height:50,width:50}}></div></div>}
    </div>;
  }
  render()
  {
    if(this.state.loading)
      return <div className="d-flex align-items-center justify-content-center" style={{height:'calc(100vh - 55px)',width:'100%'}}><Spin spinning={true}></Spin></div>;
    return (<div>
      {this.state.newBooking && <NewBooking booking={this.state.newBooking} onClose={()=>{  let self=this; self.state.events=_.filter(this.state.events,(a)=>a.title!==""); this.setState({newBooking:false});}} onSave={()=>{ this.setState({newBooking:false}); this.loadData(); }} />}
      {this.state.editBooking && <EditBooking booking={this.state.editBooking} onClose={()=>{  this.setState({editBooking:false});}} onSave={()=>{ this.setState({editBooking:false}); this.loadData(); }} />}
      {this.state.selectedBooking && <BookingDetail onDelete={()=>{ let events = _.filter(this.state.events,(a)=>a.class_id!==this.state.selectedBooking.class_id); this.setState({events,selectedBooking:false}) }} booking={this.state.selectedBooking} onClose={()=>this.setState({selectedBooking:false})} onReload={()=>this.loadData()} onEdit={(e)=>{ this.setState({editBooking:e,selectedBooking:false}) }} />}
      
      <div className="d-none d-sm-none d-md-block container pt-3 mb-2">
      <div className="row" style={{marginBottom:(this.state.view ==='timeGridWeek' || this.state.view ==='dayGridMonth')?-32:10}}>
      {(this.state.view ==='timeGridWeek' || this.state.view ==='dayGridMonth')?<div className="col-lg-3">
      <Button type="primary" className="ml-2" shape="circle" icon={<ArrowLeftOutlined />} onClick={()=>{this.calendarRef.current.calendar.prev()}} />
      <Button type="primary" className="ml-2" shape="circle" icon={<ArrowRightOutlined />} onClick={()=>{this.calendarRef.current.calendar.next()}} />
      </div>:<div className="col-lg-3">
      <span className="calendar-title">All Lessons</span>
      </div>}
      <div className="col-lg-6 text-center"></div>
      <div className="col-lg-3 text-center d-flex justify-content-end">
          <Button type="link" onClick={(e)=>{this.setState({view:'timeGridWeek'}); if(this.calendarRef && this.calendarRef.current) this.calendarRef.current.calendar.changeView('timeGridWeek');}}>Week</Button>
          <Button type="link" onClick={(e)=>{this.setState({view:'dayGridMonth'}); if(this.calendarRef && this.calendarRef.current) this.calendarRef.current.calendar.changeView('dayGridMonth');}}>Month</Button>
          <Button type="link" onClick={(e)=>{ this.setState({view:'list'});  }}>List</Button>
      </div>
      </div>
      {(this.state.view ==='timeGridWeek' || this.state.view ==='dayGridMonth') && 
      <FullCalendar 
      defaultView={this.state.view}
      selectable={localStorage.getItem("group_id")==='4'}
      selectAllow={(e)=>{
        
        let t = _.find(this.state.events,{title:""});
        let self =this;
        if(t)
        {
          t.start= e.startStr;
          t.end= moment.duration(moment(e.end).diff(moment(e.start)))<3600000?moment(moment(e.start).add(moment.duration(1, 'hours'))).format("YYYY-MM-DD hh:mm:ss"):e.endStr;
          this.setState({set:true})
        }
        else
        {
          
          self.state.events.push({title:"",start:e.startStr,end:moment(moment(e.start).add(moment.duration(1, 'hours'))).format("YYYY-MM-DD hh:mm:ss")});
          this.setState({set:true})
        }
        
        return e.endStr.substr(0,10)===e.startStr.substr(0,10);
      }}
      select={(e)=>{
        //Modal.info({title:`${e.startStr} ${e.endStr}`,onOk:()=>{ let self=this; self.state.events=_.filter(this.state.events,(a)=>a.title!==""); this.setState({set:true}) }})
        this.setState({newBooking:{start:moment(e.start),end:moment.duration(moment(e.end).diff(moment(e.start)))<3600000?moment(moment(e.start).add(moment.duration(1, 'hours'))):moment(e.end)}});
      }}
      allDaySlot={false} 
      minTime={localStorage.getItem("start_time")} 
      maxTime={localStorage.getItem("end_time")} 
      slotDuration={"00:15:00"} 
      slotLabelInterval={"01:00"} 
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: false,
        hour12:false
      }}
      eventTimeFormat={{
        hour: '2-digit',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: false,
        hour12:false
      }}
      headerToolbar={{
        start: null, 
        center: 'title',
        end: null, 
      }}
      events={(e,c)=>{
          c(this.state.events);
      }}
      eventRender={(e)=>{

      }}
      eventClick={(e)=>{
        let selectedBooking = _.find(this.state.events,{class_id:e.event._def.extendedProps.class_id})
        this.setState({selectedBooking})
      }}
      ref={this.calendarRef}
      firstDay={1}
      nowIndicator={false}
      plugins={[ timeGridPlugin,dayGridPlugin,interactionPlugin ]}
      
    /> }
      
      {this.state.view==="list" && <div>
        <table className="simpletable" border="1">
              <tr>
                <th>Date</th>
                <th>Time</th>
                <th>Student</th>
                <th>Type</th>
                <th>Status</th>
              </tr>
              {this.state.events.map((item)=><tr onClick={()=>{ this.setState({selectedBooking:item}) }}>
                <td>{moment(item.start).format("ddd MMM D")}</td>
                <td>{moment(item.start).format("HH:mm")} - {moment(item.end).format("HH:mm")}</td>
                <td>{item.title}</td>
                <td>{item.service_type}</td>
                <td style={{position:'relative',paddingLeft:38}}><div className={"fc-event " + item.className} style={{width:15,height:15,position:'absolute',top:20,left:10}}></div> {item.status}</td>
                
              </tr>)}
        </table>
        </div>}

        </div>
        <div className="d-block d-sm-block d-md-none">
          <div style={{position:'fixed',bottom:15,right:15}}><Button onClick={()=>{this.setState({newBooking:{}})}} size="large" shape="circle" type="primary" icon={<PlusOutlined />}></Button></div>
          <div id="mobilediv" style={{height:'calc(100vh - 55px)',width:'100%',overflowY:'auto'}}>
          {this.state.calendarArray && this.state.calendarArray.map((d)=><div className="d-flex">
            <div id={"date_"+d.date.format("YYYY-MM-DD")} style={{width:60,flexDirection:'column',height:80,color:moment(new Date()).format("YYYY-MM-DD")===d.date.format("YYYY-MM-DD")?'blue':'lightgray'}} className="d-flex align-items-center justify-content-center">
              <div>{d.date.format("DD")}</div>
              <div>{d.date.format("MMM")}</div>
            </div>
            <div style={{flex:1}}>
              {this.renderEvents(d)}
            </div>
          </div>)}
          </div>
        </div>
    </div>);
  }
  
}

export default About
