import React from 'react'
import Modal from 'antd/lib/modal'
import * as API from '../../services/accounts';
import Radio from 'antd/lib/radio'
import Table from 'antd/lib/table'
import { connect } from 'react-redux'
import {siteUrl} from '../../services/baseService'
import EarningDetail from './components/EarningDetail'
import Spin from 'antd/lib/spin'
class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit:false,
      selected:"Earnings",
      earnings:[],
      payments:[]

    }
    
  }
  componentDidMount()
  {
    this.loadData();
    
  }
  loadData()
  {
    this.setState({loading:true })
    API.tutor_income().then((response)=>{
      this.setState({earnings:response.data.earnings,payments:response.data.payments,loading:false})

    })
    .catch((error)=>{
      this.setState({loading:false})
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
  }
  
  render()
  {
    const columns1 = [
      {
        title: 'Processed',
        dataIndex: 'processed',
        key: 'processed',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      },
      {
        title: 'Student',
        dataIndex: 'student',
        key: 'student',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      },
      {
        title: 'Details',
        dataIndex: 'details',
        key: 'details',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      },
      {
        title: 'Fee',
        dataIndex: 'tutor_fee',
        key: 'tutor_fee',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">&pound;{val}</div>
      }
    ];
    const columns2 = [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      },
      {
        title: 'Earnings period',
        dataIndex: 'period',
        key: 'period',
        sorter: false,
        render: (val,record)=><div title={val} className="text_overlap"><a  rel="noopener noreferrer" href={`${siteUrl}${record.link}`} target="_blank">{val}</a></div>
      },
      {
        title: 'Amount',
        dataIndex: 'net_fee',
        key: 'net_fee',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">&pound;{val}</div>
      },
      {
        title: 'Fee',
        dataIndex: 'tutor_fee',
        key: 'tutor_fee',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">&pound;{val}</div>
      }
    ];
    return (<div className="container mt-5 mb-5">
      {this.state.selectedRecord && <EarningDetail detail={this.state.selectedRecord} onClose={()=>this.setState({selectedRecord:false})} />}
      <div className="row">
          <div className="col-md-12">
            <Radio.Group value={this.state.selected} onChange={(e)=>{ this.setState({selected:e.target.value})}}>
              <Radio.Button value="Earnings">Earnings</Radio.Button>              
              <Radio.Button value="Payments">Payments</Radio.Button>              
            </Radio.Group>
            <Spin spinning={this.state.loading}>
            <Table 
              className="mt-2"
              columns={this.state.selected==="Earnings"?columns1:columns2}
              onRow={(record, index) => {
                return {
                  onClick: event => { if(this.state.selected==="Earnings") this.setState({ selectedRecord: record });  },
                };
              }}
              dataSource={this.state.selected==="Earnings"?this.state.earnings:this.state.payments}
            />
            </Spin>
          </div>
      </div>
      
      
    </div>);
  }
  
}
const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount)
