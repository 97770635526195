import React from 'react'
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

import _ from 'lodash';
// set up cookies
const { Option } = Select;
class TutorCourseSubject extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
          department_id:undefined,
          tutor_departments:[]
        }
    }
    componentDidMount()
    {
      this.setState({tutor_departments:this.props.tutor_departments})
    }
    
    
  
    
  
  render() {
    let departments = [];
    this.props.departments.forEach((item)=>{
      var ind =_.findIndex(this.state.tutor_departments,{id:item.id});
      if(ind===-1 || this.props.tutor_departments[ind].cleared)
        departments.push(item);
    })
    return (
      <div>
        {departments.length>0 && 
      <div className="d-flex">
        <div style={{paddingRight:7,paddingTop:7,width:170}}>
          
          <Select
            showSearch
            allowClear
            placeholder="Subject"
            value={this.state.department_id}
            optionFilterProp="children"
            onChange={(val)=>this.setState({department_id:val})}
            filterOption={(input, option) =>{
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            }
          >
            {departments.map((item)=><Option value={item.id}>{item.name}</Option>)}
            
          </Select>

          
        </div>
        <div style={{paddingRight:7,paddingTop:7}}>
          <Button onClick={()=>{
            if(!this.state.department_id)
              return;
            this.state.tutor_departments.push({id:this.state.department_id,subject_update:0,course_update:0,courses:[],subjects:[]});
            this.setState({department_id:undefined});
            this.props.onChange(this.state.tutor_departments);
          }}>Add</Button>
        </div>
        </div>}
          {this.state.tutor_departments.map((item)=>!item.cleared?<div style={{borderTop:'1px solid #ccc',marginTop:22,paddingTop:15}}>

            <div  style={{paddingRight:7,paddingTop:7,lineHeight:'32px',fontSize:18,fontWeight:'bold'}}>
               <Input readOnly="true" style={{width:163,marginRight:7}} value={_.find(this.props.departments,{id:item.id}).name} /> 
               <Button onClick={()=>{
            item.cleared = 1;
            item.subject_update = 1;
            item.course_update=1;
            item.subjects = [];
            item.courses = [];

            this.setState({set:true});
            this.props.onChange(this.state.tutor_departments);
          }}>Clear</Button>

            </div>
        <div style={{paddingRight:7,paddingTop:7}}>
          
          <Select
            showSearch
            allowClear
            style={{width:'100%'}}
            placeholder="Course"
            value={item.courses}
            mode="multiple"
            optionFilterProp="children"
            onChange={(val)=>{ item.courses=val; item.course_update=1; this.setState({set:true}); this.props.onChange(this.state.tutor_departments); }}
            filterOption={(input, option) =>{
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            }
          >
            {_.uniqBy(_.filter(this.props.courses,{department_id:item.id}),"id").map((item)=><Option value={item.id}>{item.name}</Option>)}
            
          </Select>

          
        </div>
        <div  style={{paddingRight:7,paddingTop:7}}>
          
          <Select
            showSearch
            allowClear
            style={{width:'100%'}}
            placeholder="Module"
            value={item.subjects}
            mode="multiple"
            optionFilterProp="children"
            onChange={(val)=>{ item.subjects=val;  item.subject_update=1;  this.setState({set:true}); this.props.onChange(this.state.tutor_departments); }}
            filterOption={(input, option) =>{
                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            }
          >
            {_.uniqBy(_.filter(this.props.subjects,{department_id:item.id}),"id").map((item)=><Option value={item.id}>{item.name}</Option>)}
            
          </Select>

          
        </div>
      </div>:<div />)}
        
            </div>
    );
  }
}




export default TutorCourseSubject;
