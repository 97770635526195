import React from 'react'
import Drawer from 'antd/lib/drawer'
import {siteUrl} from '../../../services/baseService'
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
 
  render()
  {
    let item=this.props.detail;
    
  return (<Drawer visible={true} title={`${item.detail.lesson_title} ${item.detail.booking_reference}`} placement="right" width="350" onClose={()=>{this.props.onClose()}}>
           <div className="input-block">
                <div className="label">Tutor</div>
                <span className="text">
                  {item.tutor}
                </span>
            </div>
            <div className="input-block">
                <div className="label">When</div>
                <span className="text">
                  {item.detail.when}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Time</div>
                <span className="text">
                  {item.detail.time}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Service</div>
                <span className="text">
                  {item.detail.service}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Course</div>
                <span className="text">
                  {item.detail.course}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Subject</div>
                <span className="text">
                  {item.detail.subject}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Price</div>
                <span className="text">
                  &pound;{item.detail.price}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Tution Fee</div>
                <span className="text">
                  &pound;{item.detail.tution_fee}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Room Fee</div>
                <span className="text">
                  &pound;{item.detail.room_fee}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Payment Mehod</div>
                <span className="text">
                  {item.payment_method}
                </span>
            </div>
            <div className="input-block">
                <div className="label">Receipt</div>
                <span className="text">
                  <a rel="noopener noreferrer" target="_blank" href={`${siteUrl}${item.detail.pdf_link}`}>{item.detail.pdf_link.split('/')[item.detail.pdf_link.split('/').length-1]}</a>
                </span>
            </div>
            
            
            

    </Drawer>);
  }
  
}

export default Index
