import React from 'react'
import * as API from '../../../services/accounts';
import {connect} from 'react-redux'
import BookingDetail from '../../components/BookingDetail'
import EditBooking from '../../components/EditBooking'               
import _ from 'lodash'
class Index extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            lessons:[],
            selected_channel:false
        }
    }
    componentDidMount()
    {
      
      this.setData();
    }
    componentDidUpdate()
    {
      this.setData();
    }
    setData()
    {
      if(this.props.selected_channel !== this.state.selected_channel)
      {
        let self=this;
        self.state.selected_channel=this.props.selected_channel;
        this.LoadData();
      }
    }
    LoadData()
    {
      API.channel_lessons(this.props.selected_channel).then((res)=>{
        res.data.lessons.forEach((d)=>{
          d.class_id=d.class_fee_id;
        })
        this.setState({lessons:res.data.lessons});
      })
    }
  render() {
    
    return (
      <div>
        {this.state.editBooking && <EditBooking booking={this.state.editBooking} onClose={()=>{  this.setState({editBooking:false});}} onSave={()=>{ this.setState({editBooking:false}); this.loadData(); }} />}
      {this.state.selectedBooking && <BookingDetail 
      
      onDelete={()=>{ let lessons = _.filter(this.state.lessons,(a)=>a.class_id!==this.state.selectedBooking.class_id); this.setState({lessons,selectedBooking:false}) }}
      booking={this.state.selectedBooking} onClose={()=>this.setState({selectedBooking:false})} onReload={()=>this.loadData()} onEdit={(e)=>{ this.setState({editBooking:e,selectedBooking:false}) }} />}
          <div className="title_block">Future Lessons</div>
          <div style={{height:'calc(50vh - 65px)',overflowY:'auto'}}>
            {this.state.lessons.map((d)=><div className="py-1 px-2 d-flex">
              <div style={{flex:1,cursor:'pointer'}} onClick={()=>this.setState({selectedBooking:d})}>
                {d.lessson_date} @ {d.from_time}-{d.to_time}, {d.name}
              </div>
            </div>)}
            </div>
          </div>
    );
  }
}




const mapStateToProps=(state)=>{
  return {
      selected_channel:state.sendbird.selected_channel
  }
}

export default connect(
  mapStateToProps,
  null
)(Index)
