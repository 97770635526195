import React from 'react'
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import Modal from 'antd/lib/modal'
import * as accounts from '../../services/accounts';
import API from '../../promises/accounts';
import SideMenu from '../app/components/SideMenu';
import Button  from 'antd/lib/button';
import EditOutlined from '@ant-design/icons/EditOutlined'
import Input from 'antd/lib/input'
import { connect } from 'react-redux'
import store from '../../store'
import EditPhone from '../components/EditPhone'
const state = store.getState();
class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit:false,
      firstname:"",
      surname:"",
      email:"",
      phone:"",
      scale:1.2,
      isCropping:false
    }
    
  }
  componentDidMount()
  {
    this.loadData();
    
  }
  loadData()
  {
         this.setState({loading:true })
         API.getuser().then((response)=>{
              this.setState({loading:false,
                firstname:response.data.users.fname,
                surname:response.data.users.lname,
                email:response.data.users.email,
                phone:response.data.users.phone,
               })
            })
            .catch((error)=>{
              this.setState({loading:false})
              Modal.error({
                title: 'Network Error',
                content: 'something went wrong..',
              });
            })
            
  }
  saveData()
  {
    this.setState({saving:true});
    let data = {
      fname:this.state.firstname,
        lname:this.state.surname,
        email:this.state.email
    };
    accounts.saveuser(data).then((response)=>{
      state.promise.userdata.data.users.firstname=this.state.fname;
      state.promise.userdata.data.users.surname=this.state.lname;
      state.promise.userdata.data.users.email=this.state.email;
      this.setState({isEdit:false,saving:false});
    })
    .catch((error)=>{
      this.setState({saving:false})
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
    
  }
  render()
  {
    
    return (<div className="container mt-5 mb-5">
      {this.state.IsEditPhone && <EditPhone onSave={(e)=>{ this.setState({phone:e,IsEditPhone:false})  }} phone={this.state.phone} onClose={()=>this.setState({IsEditPhone:false})} />}
      <div className="row">
          <div className="col-md-3">
           <SideMenu menu="1" title="Basic" />
          </div>
          <div className="col-md-9">
            <div className="input-block">
                <div className="label" style={{minWidth:120}}>First Name</div>
                {this.state.isEdit!==true?<span className="text">
                  {this.state.firstname}&nbsp;
                </span>:
                <span className="text"><Input value={this.state.firstname} onChange={(e)=>{this.setState({firstname:e.target.value})}} style={{width:'100%'}} /></span>}
            </div>
            <div className="input-block">
                <div className="label" style={{minWidth:120}}>Surname</div>
                {this.state.isEdit!==true?<span className="text">
                  {this.state.surname}&nbsp;
                </span>:
                <span className="text"><Input value={this.state.surname} onChange={(e)=>{this.setState({surname:e.target.value})}} style={{width:'100%'}} /></span>}
            </div>
            <div className="input-block">
                <div className="label" style={{minWidth:120}}>Email</div>
                {this.state.isEdit!==true?<span className="text">
                  {this.state.email}&nbsp;
                </span>:
                <span className="text"><Input value={this.state.email} onChange={(e)=>{this.setState({email:e.target.value})}} style={{width:'100%'}} /></span>}
            </div>
            <div className="input-block">
                <div className="label" style={{minWidth:120}}>Mobile Phone <Button type="link" icon={<EditOutlined />} onClick={()=>{this.setState({IsEditPhone:true})}}></Button></div>
                <span className="text">
                {this.state.phone} &nbsp;
                </span>
            </div>
            <div className="input-block">
              {this.state.isEdit?<div><Button type="primary" loading={this.state.saving} onClick={()=>this.saveData()}>Save</Button>
              <Button onClick={()=>this.setState({isEdit:false})} className="ml-2">Cancel</Button>
              </div>:
              <Button type="primary" onClick={()=>this.setState({isEdit:true})}>Edit</Button>}
            </div>
          </div>
      </div>
      
      
    </div>);
  }
  
}
const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount)
