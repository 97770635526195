import React from 'react'
import SideMenu from '../app/components/SideMenu';
import { connect } from 'react-redux'
import './payment_method.css'
import Button from 'antd/lib/button';
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import Modal from 'antd/lib/modal'
import * as API from '../../services/accounts';
import Spin from 'antd/lib/spin'

import _ from 'lodash'
function PaymentCard({Number,Expiry,Name,Type,Default,onDelete,deleting,activating,onActive})
{
  return <div className="payment_card bold">
    <div className="d-flex">
      <div style={{flex:1}}>
        **** **** **** {Number}
      </div>
      <img style={{width:50}} src={`img/${Type}.png`} alt={Type} />
    </div>
    <div className="d-flex mt-2">
      <div style={{width:70}}>
        Ex:
      </div>
      <div style={{flex:1}}>
        {Expiry}
      </div>
    </div>
    <div className="d-flex mt-2">
      <div style={{width:70}}>
        Name:
      </div>
      <div style={{flex:1}}>
        {Name}
      </div>
    </div>
    <div className="d-flex mt-3">
      <div style={{flex:1}}>
        {Default==='0' && <Button loading={activating} onClick={onActive} className="p-0" type="link">Set as Active</Button>}
      </div>
      <div style={{flex:1,textAlign:'right'}}>
        <Button loading={deleting} className="p-0" type="link" style={{color:'red'}} onClick={onDelete}>Delete</Button>
      </div>
    </div>
    
  </div>
}

class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records:[],
      
    }
    
  }
  componentDidMount()
  {
    this.loadData();
    
  }
  loadData()
  {
    this.setState({loading:true })
    API.student_payment_method().then((response)=>{
      this.setState({
        records:response.data.payments,loading:false
      });

    })
    .catch((error)=>{
      this.setState({loading:false})
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
  }
  DeleteCard(d)
  {
    Modal.confirm({title:'Are you sure you want delete this card?',content:'Please Confirm',okText:'Yes',cancelText:'No',onOk:()=>{
      this.setState({deleting:d.id})
      API.student_deletecc({cc_id:d.id}).then((response)=>{
        if(response.data.status===1)
        {
          this.setState({deleting:false,
            records:_.filter(this.state.records,(a)=>a.id!==d.id)
          });
          Modal.success({
            title: response.data.msg
          });  
        }
        else
        {
          this.setState({deleting:false})
          Modal.error({
            title: response.data.msg
          });  
        }
  
      })
      .catch((error)=>{
        this.setState({deleting:false})
        Modal.error({
          title: 'Network Error',
          content: 'something went wrong..',
        });
      })
    } })
  }
  ActiveCard(d)
  {
    
      this.setState({activating:d.id})
      API.student_activecc({cc_id:d.id}).then((response)=>{
        if(response.data.status===1)
        {
          this.setState({activating:false,
            records:_.filter(this.state.records,(a)=>a.id!==d.id)
          });
          Modal.success({
            title: response.data.msg
          });  
        }
        else
        {
          this.setState({activating:false})
          Modal.error({
            title: response.data.msg
          });  
        }
  
      })
      .catch((error)=>{
        this.setState({activating:false})
        Modal.error({
          title: 'Network Error',
          content: 'something went wrong..',
        });
      })
    
  }
  AddCard()
  {
    this.setState({adding:true})
    let data = {id:this.props.auth.Id, fname:this.props.auth.fname, lname:this.props.auth.lname};
    API.addcard(data).then((response)=>{
      this.setState({IsAddCard:true,adding:false,AddCardData:response.data})
    })
  }
  render()
  {
    
    return (<div className="container mt-5 mb-5">
      {this.state.IsAddCard && <Modal title="Add New Card" visible={true} onCancel={()=>{this.setState({IsAddCard:false})}} footer={null}>
          <iframe title="Sagepay" className="myiframe" onLoad={(e)=>{
            try
            {
              if(e.target.contentWindow.location.pathname.indexOf("sagepay_success")>-1)
              {
               
                let data = JSON.parse(e.target.contentWindow.document.body.innerHTML);
                data.user_id = this.props.id;
                data.security_key = this.state.AddCardData.SecurityKey;
                this.setState({settingCard:true});
                API.setcard(data).then((response)=>{
                  this.setState({settingCard:false,records:response.data.data})
                  Modal.success({
                    title: 'Success',
                    content: response.data.msg,
                  });
                });
                this.setState({IsAddCard:false});
              }
              if(e.target.contentWindow.location.pathname.indexOf("sagepay_failure"))
              {
                this.setState({IsAddCard:false});
                Modal.error({
                  title: 'Failure',
                  content: "Transaction Failed",
                });
              
              }
            }
            catch(err){
             
            }
          }} src={this.state.AddCardData.nexturl.NextURL} style={{border:0,width:'100%',height:437}}>

          </iframe>
        </Modal>}
      <div className="row">
          <div className="col-md-3">
           <SideMenu menu="3" title="Payment Mehotds" />
          </div>
          <div className="col-md-5">
            <Spin spinning={this.state.loading}>
            
            {this.state.records.map((d)=><PaymentCard 
            Number={d.cc_no} Expiry={`${d.expiration_month} / ${d.expiration_year}`} Name={d.name} Type={d.cc_type} Default={d.cc_default}
            deleting={this.state.deleting===d.id}
            activating={this.state.activating===d.id}
            onDelete={()=>{ this.DeleteCard(d) }}
            onActive={()=>{ this.ActiveCard(d) }}
            />)}

            <div onClick={()=>this.AddCard()} style={{backgroundColor:'white',height:150,width:'100%',flexDirection:'column',fontWeight:'bold',cursor:'pointer',fontSize:18}} className="d-flex align-items-center justify-content-center mb-3">
              <PlusOutlined  style={{fontSize:40,color:'hsl(0, 0%, 69%)'}} />
              ADD NEW CARD
            </div>
            </Spin>
            <div>
              <img src="img/visamaster.svg" alt="visamaster" width="121" />
            </div>
            <div className="mt-2">
              <img src="img/secs.png" alt="secs" width="121" />
            </div>
            
          </div>
      </div>
      
      
    </div>);
  }
  
}
const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount)
