import React from 'react'
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import Drawer from 'antd/lib/drawer'
import Spin from 'antd/lib/spin'
import * as API from '../../services/booking';
import Select from 'antd/lib/select'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
import moment from 'moment'


const options = ["09:00","09:15","09:30","09:45"
					,"10:00","10:15","10:30","10:45"
					,"11:00","11:15","11:30","11:45"
					,"12:00","12:15","12:30","12:45"
					,"13:00","13:15","13:30","13:45"
					,"14:00","14:15","14:30","14:45"
					,"15:00","15:15","15:30","15:45"
					,"16:00","16:15","16:30","16:45"
					,"17:00","17:15","17:30","17:45"
					,"18:00","18:15","18:30","18:45"
					,"19:00","19:15","19:30","19:45"
					,"20:00","20:15","20:30","20:45","21:00"
          ];
          
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date:moment(this.props.booking.start),
      start:moment(this.props.booking.start).format("HH:mm"),
      end:moment(this.props.booking.end).format("HH:mm"),
      students:[],
      loading:false
    }
    
  }
  componentDidMount()
  {
    console.log(this.props.booking)
    this.setState({loading:true});
    API.tutor_booking_list({class_fee_id:this.props.booking.class_id,md:1}).then((res)=>{
      this.setState({students:res.data.users,loading:false})
    }).catch((err)=>{
      this.setState({loading:false});
    });
  }
  SubmitData(data)
  {
    data.date=this.state.date.format("YYYY-MM-DD");
    data.start=this.state.start;
    data.end=this.state.end;
    data.token=localStorage.getItem('user_token');
    data.id = this.props.booking.class_fee_id;
    this.setState({booking:true})
    API.ajax_react_user_reconfirm(data).then((res)=>{
      if(res.data.status===1)
      {
        this.props.onSave();
      }
      else
      {
        Modal.error({title:res.data.message})
        this.setState({booking:false})
      }
    }).catch(err=>{
      Modal.error({title:'Something Went Wrong'})
    })
  }
  render()
  {
    
    return (<Drawer visible={true} placement="right" width="350" title={"Edit Booking"} onClose={()=>{this.props.onClose()}}>
      <Spin spinning={this.state.loading}>
        <Form layout="vertical" onFinish={(e)=>{ this.SubmitData(e); }} initialValues={{student:this.props.booking.student_id,venue:this.props.booking.service_type_id}}>
            <div className="input-block">
                <div className="label">Student</div>
                <span className="text">
                  <Form.Item name="student" rules={[{required:true,message:'Please select student'}]}>
                    <Select placeholder="Select..." style={{width:200}}>
                      {this.state.students.map((d)=><Select.Option value={d.id}>{d.fname}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  
                </span>
            </div>
            <div className="input-block">
                <div className="label">Date</div>
                <span className="text" style={{paddingRight:7}}>
                  <DatePicker value={this.state.date} onChange={(e)=>{this.setState({date:e})}} format="ddd MMM D" allowClear={false} style={{width:200}} />
                </span>
            </div>
            <div className="input-block">
                <div className="label">Time</div>
                <span className="text" style={{paddingRight:7}}>
                  <Select  style={{width:91,marginRight:5}} value={this.state.start} placeholder="Start" onChange={(e)=>{ let diff = options.indexOf(this.state.end) - options.indexOf(e);  if(diff<=1) this.setState({end:options[options.indexOf(e)+2]}) ; this.setState({start:e}) }}>
                    {options.map((d)=><Select.Option value={d}>{d}</Select.Option>)}
                  </Select> - <Select  style={{width:91,marginLeft:5}} value={this.state.end} placeholder="End" onChange={(e)=>{ let diff = options.indexOf(e) - options.indexOf(this.state.start); if(diff<=1) this.setState({start:options[options.indexOf(e)-2]});  this.setState({end:e}) }}>
                    {options.map((d)=><Select.Option value={d}>{d}</Select.Option>)}
                  </Select>
                </span>
            </div>
            <div className="input-block">
                <div className="label">Venue</div>
                <span className="text">
                  <Form.Item name="venue" rules={[{required:true,message:'Please select venue'}]}>
                    <Select placeholder="Select..." style={{width:200}}>
                      <Select.Option value={'3'}>At the center</Select.Option>  
                      <Select.Option value={'4'}>Online</Select.Option>  
                    </Select>
                  </Form.Item>
                  
                </span>
            </div>
            <div className="input-block">
              <Button loading={this.state.booking} htmlType="submit" type="primary" style={{width:'100%'}}>Book Now</Button>
            </div>
            </Form>
            
            </Spin>
    </Drawer>);
  }
  
}

export default Index
