import baseService from './baseService';
import _ from 'lodash'
import baseService2 from './baseService2';
export function getevents(data){
    return baseService.post('/panel/all_events',data);
}

export function getuser(){
    return baseService.get('/panel/get_user');
}
export function saveuser(data){
    return baseService.post('/panel/user_save',data);
}
export function update_public_pro(data){
    return baseService.post('/panel/update_public_pro',data);
}
export function add_qualification(data){
    return baseService.post('/panel/add_qualification',data);
}

export function update_msg(data){
    if(localStorage.getItem("group_id")==='3')
        return baseService.post('/panel/update_student_msg',data);
    else
        return baseService.post('/panel/update_tutor_msg',data);
}
export function delete_qualification(data){
    return baseService.post('/panel/delete_qualification',data);
}
export function update_tutor_teach(data){
    return baseService.post('/panel/update_tutor_teach',data);
}

export function addavailability(data){
    return baseService.post('/panel/user_add_availability',data);
}
export function deleteavailability(data){
    return baseService.post('/panel/user_remove_availability',data);
}

export function useravailibilities(data){
    return baseService.post('/panel/user_availabilities',data);
}
export function student_payment_method(data){
    return baseService.post('/panel/student_payment_method',data);
}

export function addcard(data){
    return baseService.post('/sagepay/addcard',data);
}
export function downloadlink(url){
    if(url)
     window.location.href=  baseService.defaults.baseURL +  url;
}
export function setcard(data){
    return baseService.post('/student/sagepay_data',data);
}
export function student_deletecc(data){
    return baseService.post('/panel/student_deletecc',data);
}
export function student_activecc(data){
    return baseService.post('/panel/student_activecc',data);
}
export function student_history(data){
    return baseService.post('/panel/student_history',data);
}
export function message_documents(data){
    return baseService.post('/panel/message_documents',data);
}
export function tutor_income(){
    return baseService.post('/panel/tutor_income',{});
}
export function mobileverify(data){
    return baseService2.post('/ajaxs/mobileverify',data);
}
export function mobile_verify_otp(data){
    return baseService.post('/panel/mobile_verify_otp',data);
}


export function channel_lessons(channel){

    let data = {};
    if(localStorage.getItem("group_id")==='4')
    {
        data = {student_id:_.filter(channel.members,(a)=>a.userId!==localStorage.getItem("Id"))[0].userId}
        return baseService.post('/panel/tutor_mystudent_lessons',data);
    }
    else
    {
        data = {tutor_id:_.filter(channel.members,(a)=>a.userId!==localStorage.getItem("Id"))[0].userId}
        return baseService.post('/panel/student_mytutor_lessons',data);
    }
}
export function chat_files(id) {
    return baseService2.get('ajaxs/delete_uploaded_document/'+id);
}