import store from '../store'
import * as API from '../services/accounts';
import _ from 'lodash'
const state = store.getState();
class Index {

    constructor()
    {
      
      if(!state.promise)
      {
        state.promise={};
      }
    }
    getuser()
    {
        let promise = new Promise((resolve, reject) => {   
        if(state.promise.userdata)
        {
          resolve(state.promise.userdata);
        }
        else
        {
          API.getuser().then((response)=>{
            if(response.data.tutor_departments)
            {
              response.data.tutor_departments.forEach((item,index)=>{
                item.id=item.department_id;
                let subjects = _.filter(response.data.tutor_modules,{course_id:item.id});
                let subs = subjects.map(item=>item.subject_id);
                response.data.tutor_departments[index].subjects = subs;
                response.data.tutor_departments[index].subject_update = 0;

                let courses = _.filter(response.data.tutor_courses,{department_id:item.id});
                let cous = courses.map(item=>item.course_id);
                response.data.tutor_departments[index].courses = cous;
                response.data.tutor_departments[index].course_update = 0;
              })
            }
            state.promise.userdata=response;
            resolve(response)
          }).catch((err)=>{
            reject(err)
          })  
        }
      })
      return promise; 
    }   
}




export default (new Index())
