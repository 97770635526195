import Button from 'antd/lib/button';
import React from 'react'
import { connect } from 'react-redux'

import baseService from '../../services/baseService';
import EmailLogin from './components/EmailLogin';
import PhoneLogin from './components/PhoneLogin';
import VerifyOTP from './components/VerifyOTP';

class Index extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        OTPSent:false,
        Type:'email',
        Email:'',
        Phone:'',
        CountryCode:'',
        message:''
      }
    }

    render() {
      return (
  <div className="d-flex justify-content-center align-items-center body100">
    <div style={{width:'100%',maxWidth:400}}>
        <div className="text-center">
          <img alt="logo" src="/img/logo.svg" style={{width:'100%',maxWidth:300}} />
        </div>
        <h1 className="text-center">Log in</h1>
        {!this.state.OTPSent?<div>
          {this.state.Type==='email'?<EmailLogin OTPSent={(e,m)=>{this.setState({Email:e,OTPSent:true,message:m})}} />:
          <PhoneLogin OTPSent={(e,c,m)=>{this.setState({Phone:e,CountryCode:c,OTPSent:true,message:m})}} />
          }
        </div>:<div>
            <VerifyOTP Email={this.state.Email} Type={this.state.Type} 
            message={this.state.message}
            phone={this.state.Phone}
            country_code={this.state.CountryCode}
            LoggedIn={(data)=>{ 
              localStorage.setItem("authToken",data.authToken);
              baseService.addAuthToken();
              this.props.loggedIn(data);
            }}/>
        </div>}
        <div className="text-center">
          {this.state.Type==='email'?<Button type="link" style={{fontSize:18}} onClick={()=>this.setState({Type:'phone'})}>Login via SMS</Button>:
          <Button type="link" style={{fontSize:18}} onClick={()=>this.setState({Type:'email'})}>Login via Email</Button>}
        </div>
        <div className="mt-5 text-center">© 2019-2020</div>
      </div>
  </div>)
    }
}

const mapStateToProps = () => ({
  
})

const mapDispatchToProps = dispatch => {
  return {
      loggedIn: (data) => {
          dispatch({ type: "USER_LOGIN", payload:data });
      }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
