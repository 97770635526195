import React, {Component} from "react";
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Dropdown from 'antd/lib/dropdown'
import Menu  from "antd/lib/menu";
import DownOutlined  from '@ant-design/icons/DownOutlined'


const menus = (
  <Menu>
    <Menu.Item>
        <Link to="/my_availability">Availability</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/my_account">Basic</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/payment_method">Payment Method</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/history">History</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/notification">Notification</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/security">Security</Link>
    </Menu.Item>
    
    
  </Menu>
);
const menut = (
  <Menu>
    <Menu.Item>
        <Link to="/my_availability">Availability</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/my_account">Basic</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/my_account/profile">Public Profile</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/my_account/teach">What I Teach</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/security">Messages</Link>
    </Menu.Item>
  </Menu>
);

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageloading:false
    }
    
  }
  
  
    componentDidMount() {
      
      
    }
    
	componentWillReceiveProps(nextProps) {
    
	}
   
  render() {
      
    
    return (
        <div className="sidemenu">
            <div className="d-none d-sm-none d-md-block">
              {this.props.auth.group_id==='3' && <ul>
                  <li className={this.props.menu==="1"?"active":""}><Link to="/my_account">Basic</Link></li>
                  <li className={this.props.menu==="2"?"active":""}><Link to="/my_availability">Availability</Link></li>
                  <li className={this.props.menu==="3"?"active":""}><Link to="/payment_method">Payment Method</Link></li>
                  <li className={this.props.menu==="4"?"active":""}><Link to="/payment_history">History</Link></li>
                  <li className={this.props.menu==="6"?"active":""}><Link to="/security">Notifications</Link></li>
              </ul>}
              {this.props.auth.group_id==='4' && <ul>
                  <li className={this.props.menu==="2"?"active":""}><Link to="/my_availability">Availability</Link></li>
                  <li className={this.props.menu==="1"?"active":""}><Link to="/my_account">Basic</Link></li>
                  <li className={this.props.menu==="3"?"active":""}><Link to="/my_account/profile">Public Profile</Link></li>
                  <li className={this.props.menu==="4"?"active":""}><Link to="/my_account/teach">What I Teach</Link></li>
                  <li className={this.props.menu==="6"?"active":""}><Link to="/security">Messages</Link></li>
              </ul>}
            </div>
            <div className="d-block d-sm-block d-md-none mb-2">
              <Dropdown overlay={this.props.auth.group_id==='4'?menut:menus}>
              <div>
                <b>{this.props.title}</b> <DownOutlined />
              </div>
              </Dropdown>
            </div>


        </div>
    )

  }
}


const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideMenu)