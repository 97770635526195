import React from 'react'
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import Drawer from 'antd/lib/drawer'
import Spin from 'antd/lib/spin'
import * as API from '../../services/booking';
import Select from 'antd/lib/select'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Button from 'antd/lib/button'
import Modal from 'antd/lib/modal'
import _ from 'lodash'


          
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date:this.props.booking.start,
      start:this.props.booking.start?this.props.booking.start.format("HH:mm"):undefined,
      end:this.props.booking.end?this.props.booking.end.format("HH:mm"):undefined,
      students:[],
      slots:[],
      courses:[],
      subjects:[],
      loading:false
    }
    
  }
  componentDidMount()
  {
    this.setState({loading:true});
    API.tutor_booking_list({class_fee_id:this.props.booking.class_id,md:1}).then((res)=>{
      this.setState({students:res.data.users,loading:false,slots:res.data.slots,courses:res.data.courses,subjects:res.data.subjects})
    }).catch((err)=>{
      this.setState({loading:false});
    });
  }
  SubmitData(data)
  {
    data.date=this.state.date.format("YYYY-MM-DD");
    data.start=this.state.start;
    data.end=this.state.end;
    data.token=localStorage.getItem('user_token');
    this.setState({booking:true})
    API.ajax_react_user_confirm(data).then((res)=>{
      if(res.data.status===1)
      {
        this.props.onSave();
      }
      else
      {
        Modal.error({title:res.data.message})
        this.setState({booking:false})
      }
    }).catch(err=>{
      Modal.error({title:'Something Went Wrong'})
    })
  }
  render()
  {
    let options = this.state.slots;
    return (<Drawer visible={true} placement="right" width="350" title={"New Booking"} onClose={()=>{this.props.onClose()}}>
      <Spin spinning={this.state.loading}>
        <Form ref={(r)=>this.form=r} layout="vertical" onFinish={(e)=>{ this.SubmitData(e); }} initialValues={{date:this.state.date,start:this.state.start,end:this.state.end}}>
            <div className="input-block">
                <div className="label">Student</div>
                <span className="text">
                  <Form.Item name="student" rules={[{required:true,message:'Please select student'}]}>
                    <Select placeholder="Select..." style={{width:200}}>
                      {this.state.students.map((d)=><Select.Option value={d.id}>{d.fname}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  
                </span>
            </div>
            <div className="input-block">
                <div className="label">Date</div>
                <span className="text" style={{paddingRight:7}}>
                  <DatePicker style={{width:200}} onChange={(e)=>{ this.setState({date:e})}} value={this.state.date} format="ddd MMM D" allowClear={false} />
                </span>
            </div>
            <div className="input-block">
                <div className="label">Time</div>
                <span className="text" style={{paddingRight:7}}>
                  <Select style={{width:91,marginRight:5}} value={this.state.start} placeholder="Start" onChange={(e)=>{ let diff = options.indexOf(this.state.end) - options.indexOf(e);  if(diff<=1) this.setState({end:options[options.indexOf(e)+2]}) ; this.setState({start:e}) }}>
                    {options.map((d)=><Select.Option value={d}>{d}</Select.Option>)}
                  </Select> - <Select  style={{width:91,marginLeft:5}} value={this.state.end} placeholder="End" onChange={(e)=>{ let diff = options.indexOf(e) - options.indexOf(this.state.start); if(diff<=1) this.setState({start:options[options.indexOf(e)-2]});  this.setState({end:e}) }}>
                    {options.map((d)=><Select.Option value={d}>{d}</Select.Option>)}
                  </Select>
                </span>
            </div>
            <div className="input-block">
                <div className="label">Venue</div>
                <span className="text">
                  <Form.Item name="venue" rules={[{required:true,message:'Please select venue'}]}>
                    <Select placeholder="Select..." style={{width:200}}>
                      <Select.Option value={3}>At the center</Select.Option>  
                      <Select.Option value={4}>Online</Select.Option>  
                    </Select>
                  </Form.Item>
                  
                </span>
            </div>
            <div className="input-block">
                <div className="label">Course</div>
                <span className="text">
                  <Form.Item name="course_id" rules={[{required:true,message:'Please select venue'}]}>
                    <Select placeholder="Select..." style={{width:200}} onChange={(e)=>this.setState({course_id:e})}>
                      {this.state.courses && this.state.courses.map((d)=><Select.Option value={d.id}>{d.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  
                </span>
            </div>
            <div className="input-block">
                <div className="label">Subject</div>
                <span className="text">
                  <Form.Item name="subject_id" rules={[{required:true,message:'Please select venue'}]}>
                    <Select placeholder="Select..." style={{width:200}}>
                      
                      {this.state.course_id && this.state.subjects && _.filter(this.state.subjects,{course_id:this.state.course_id}).map((d)=><Select.Option value={d.subject_id}>{d.name}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  
                </span>
            </div>
            <div className="input-block">
              <Button loading={this.state.booking} htmlType="submit" type="primary" style={{width:'100%'}}>Book Now</Button>
            </div>
            </Form>
            
            </Spin>
    </Drawer>);
  }
  
}

export default Index
