import React from 'react'
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import SideMenu from '../app/components/SideMenu';
import Availability from './Availibility'
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
    }
    
  }
  
  
  render()
  {
    
    return (<div className="container mt-5 mb-5">
      <div className="row">
          <div className="col-md-3">
            <SideMenu menu="2" title="Availability" />
          </div>
          <div className="col-md-9">
            <div className="d-none d-sm-none d-md-block">
              <Availability />
            </div>
            <div className="d-block d-sm-block d-md-none mb-2">
              <Availability dayview={true} />
            </div>
          </div>
          
      </div>
      
      
    </div>);
  }
  
}

export default Index
