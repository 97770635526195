import React from 'react'
import * as tutors from '../../../services/accounts';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Drawer from 'antd/lib/drawer';
import Select from 'antd/lib/select';


// set up cookies
const { Option } = Select;
class tutorQualificationForm extends React.Component {
  state = {
    loading:false,
   
  };
  componentDidMount()
  {
   
  }
  handleSubmit = values => {
    
    
        this.setState({loading:true});
        values.user_id=this.props.user_id;
        tutors.add_qualification(values).then((response)=>{
            if(response.data.status===1)
            {
                this.props.onAdd(response.data.data);
            }
            this.setState({loading:false});
        }).catch((error)=>{
          this.setState({loading:false});
        })
        
    
  };

  
  render() {
 
    return (
      <Drawer visible={true} width="350"  title="Add Qualification" footer={null} onClose={()=>this.props.onCancel()}>
      <Form ref={ref=>this.form=ref}  layout="vertical" onFinish={this.handleSubmit}>
        <div className="input-block2">
            <div className="label">Qualification</div>
            <span className="text">
            <Form.Item label="" name="course" rules={[{ required: true, message: 'Please input qualification!' }]}>
                <Select
                    showSearch
                    allowClear
                    placeholder="Course"
                    style={{width:310}}
                    optionFilterProp="children"
                    filterOption={(input, option) =>{
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    }
                >
                    {this.props.qualification_course.map((item)=><Option value={item.course_name}>{item.course_name}</Option>)}
                    
                </Select>
                </Form.Item>
                
            </span>
        </div>
        <div className="input-block2">
            <div className="label">Institution</div>
            <span className="text">
            <Form.Item label="" name="institution" rules={[{ required: true, message: 'Please input institution!' }]}>
                <Select
                    showSearch
                    allowClear
                    placeholder="Course"
                    style={{width:310}}
                    optionFilterProp="children"
                    filterOption={(input, option) =>{
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    }
                >
                    {this.props.qualification_institution.map((item)=><Option value={item.institution_name}>{item.institution_name}</Option>)}
                    
                </Select>
            </Form.Item>
                
            </span>
        </div>
        <div className="input-block2">
            <div className="label">Subject</div>
            <span className="text">
            <Form.Item label="" name="subject" rules={[{ required: true, message: 'Please input institution!' }]}>
                <Select
                    showSearch
                    allowClear
                    placeholder="Course"
                    style={{width:310}}
                    optionFilterProp="children"
                    filterOption={(input, option) =>{
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    }
                >
                    {this.props.qualification_institution.map((item)=><Option value={item.institution_name}>{item.institution_name}</Option>)}
                    
                </Select>
            </Form.Item>
                
            </span>
        </div>
        <div className="input-block2">
            <div className="label">Grade</div>
            <span className="text">
            <Form.Item label="" name="grade">
            <Select
                showSearch
                allowClear
                placeholder="Grade"
                style={{width:310}}
                optionFilterProp="children"
                filterOption={(input, option) =>{
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                }
            >
                {this.props.qualification_grade.map((item)=><Option value={item.grade_name}>{item.grade_name}</Option>)}
                
            </Select>
            </Form.Item>
                
            </span>
        </div>
        
        
        
        <div className="input-block">     
          <Button type="primary" style={{width:'100%'}} htmlType="submit" loading={this.state.loading}>
            Save
          </Button>
        </div>
          
        
      </Form>
      </Drawer>
      
    );
  }
}



export default tutorQualificationForm;