import _ from 'lodash'
const initialState = {
    channels:[],
    selected_channel:null,
    messages:[],
    sendMessage:''
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case 'CHANNEL_LIST':
        return {
          ...state,
          channels:action.payload
        }
      case 'CHANNEL_UPDATE':
        
        let i = _.findIndex(state.channels,{url:action.payload.url});
        if(i>-1)
          state.channels[i]=action.payload;
        return {
          ...state,
          channels:_.clone(state.channels)
        } 
      case 'CHANNEL_SELECT':
        return {
          ...state,
          selected_channel:action.payload
        }
      case 'MESSAGE_LIST':
        return {
          ...state,
          messages:action.payload
        } 
      case 'MESSAGE_SEND':
        return {
          ...state,
          sendMessage:action.payload
        } 
      case 'MESSAGE_ADD':
        if(state.selected_channel===action.payload.channel)
        {
          let mes = state.messages;
          mes.push(action.payload.message);
          return {
            ...state,
            messages:_.clone(mes)
          }
        }
        else
        return {
          ...state
        }
        
 
        
        
        
      
      default:
        return state
    }
  }