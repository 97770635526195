import React from 'react'
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Modal from 'antd/lib/modal'
import * as accounts from '../../../services/accounts';
import connect from 'react-redux/lib/connect/connect'
import moment from 'moment';
import Switch from 'antd/lib/switch'

const Option = Select.Option;


class AvailabilityForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading:false,
          is_repeat:false,
        }
      }
      componentDidMount()
      {
        
      }
  handleSubmit = values => {
    
    
          this.setState({
            loading: true
          })
        
          var data = values;
          if(this.props.formdata.id!==0)
            data.id = this.props.formdata.id;
          data.is_repeat=false;
          data.repeats=1;

         
          accounts.addavailability(data).then((response)=>{
            this.setState({loading:false});
            this.props.saved();
            
          })
          .catch((error)=>{
            this.setState({
              loading:false
            })
            Modal.error({
              title: 'Network Error',
              content: 'something went wrong..',
            });
          })

          
          
    
  };

  DeleteAvalibility()
  {
    this.setState({
      deleting: true
    })
  
    var data = {};
    data.availability_id = this.props.formdata.id;
    accounts.deleteavailability(data).then((response)=>{
      this.setState({deleting:false});
      this.props.saved();
      
    })
    .catch((error)=>{
      this.setState({
        deleting:false
      })
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
  }
  render() {
    const dateoptions = [];
    for(var i=0;i<7;i++)
    {
      var d = new Date(Date.now() + i*24*60*60*1000);
      dateoptions.push({datevalue:moment(d).format("YYYY-MM-DD"),datetext:moment(d).format("ddd MMM DD YYYY")});
    }

    const timeoptions = ["09:00","09:15","09:30","09:45"];
    for(i=10;i<=21;i++)
    {
      timeoptions.push(i + ":00");
      timeoptions.push(i + ":15");
      timeoptions.push(i + ":30");
      timeoptions.push(i + ":45");
    }
    timeoptions.push("22:00");

    const repeatoptions = [{value:27,text:"Forever (26 Weeks)"}];
    for(i=1;i<=25;i++)
    {
      repeatoptions.push({value:i+1,text:i+ " Week(s)"});  
    }
    
    return (
      <Modal title={this.props.isEdit?"Edit Availability": "Add Availability"} footer={null} visible={true} onCancel={()=>{ this.props.onClose()}}>
      <Form ref={(r)=>this.form=r} onFinish={this.handleSubmit} initialValues={this.props.formdata}>
        
          
        <Form.Item label="Date" name="avail_date" rules={[{ required: true, message: '' }]}>
          
            <Select>
              {dateoptions.map((item)=><Option value={item.datevalue}>{item.datetext}</Option>)}
              
            </Select>
        </Form.Item>
            <div className="row">
            <div className="col-6">
        <Form.Item label="From"  name="from_time" rules={[{ required: true, message: '' }]}>
          
            <Select>
              {timeoptions.map((item)=><Option value={item}>{item}</Option>)}
              
            </Select>
        </Form.Item>
          </div>
          <div className="col-6">
        <Form.Item label="To"  name="to_time" rules={[{ required: true, message: '' }]}>
          <Select>
              {timeoptions.map((item)=><Option value={item}>{item}</Option>)}
            </Select>
        </Form.Item>
          </div>
        </div>

        {1==2 && <Form.Item label="Is This a Repeat Slot?"  name="is_repeat" valuePropName="checked">
          
            <Switch onChange={(is_repeat)=>{this.setState({is_repeat}); this.form.setFieldsValue({repeats:is_repeat?27:1})}} />,
          
        </Form.Item>}
        
        {1==2 && <Form.Item label="How Many Repeats ?" className={this.state.is_repeat?"":"d-none"}  name="repeats" rules={[{ required: true, message: '' }]}>
          
            <Select>
              {repeatoptions.map((item)=><Option value={item.value}>{item.text}</Option>)}
            </Select>
        </Form.Item>}

        
        <Form.Item label="Is Online?"  name="is_online"  valuePropName="checked">
          
            <Switch />
        </Form.Item>


        
          
           
            <Button type="primary" htmlType="submit" className="mt-3 mr-3" loading={this.state.loading}>
           Save
          </Button>  
          {this.props.isEdit && <Button type="danger" loading={this.state.deleting} className="mt-3" onClick={()=>{ this.DeleteAvalibility()}}>
           Delete
          </Button>}
         
          
          
        
       
      </Form>
      </Modal>
    );
  }
}

const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}

export default connect(
  mapStateToProps,
  null
)(AvailabilityForm)
