import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Menu from './components/Menu'
import Home from '../home'
import About from '../about'
import MyAccount from '../my_account'
import MyAccountProfile from '../my_account/profile'
import MyAccountTeach from '../my_account/teach'
import Availability from '../availability'
import PaymentMethod from '../my_account/payment_method'
import History from '../my_account/history'
import Notification from '../notification'
import Security from '../my_account/messages'
import Messages from '../messages'
import Income from '../my_account/income'

import Logout from '../logout'
import { connect } from 'react-redux'

import Sendbird from '../components/SendBird/index'



class App extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
       
      }
    }

componentDidMount()
{
  console.log(this.props.auth.isLoggedIn);
}
render() {
  
  return (
  <div>
    {this.props.auth.isLoggedIn===true &&  <header>
      <Menu />
    </header>}
    {this.props.auth.isLoggedIn===true?
    <main>
      <Sendbird />
      <Switch>
      <Route exact path="/" component={About} />
      <Route exact path="/my_account" component={MyAccount} />
      <Route exact path="/my_account/profile" component={MyAccountProfile} />
      <Route exact path="/my_account/teach" component={MyAccountTeach} />
      <Route exact path="/income" component={Income} />
      <Route exact path="/my_availability" component={Availability} />
      <Route exact path="/payment_method" component={PaymentMethod} />
      <Route exact path="/payment_history" component={History} />
      <Route exact path="/notifications" component={Notification} />
      <Route exact path="/messages" component={Messages} />
      <Route exact path="/security" component={Security} />
      <Route exact path="/logout" component={Logout} />
      <Route component={About} />
      </Switch>
    </main>:<main>
    
    <Switch>  <Route exact path="/" component={Home} />
      <Route exact path="/login" component={Home} />
      <Route exact path="/logout" component={Home} />
      <Route component={Home} />
    </Switch>
    </main>}
    
  </div>
)

  }
}

const mapStateToProps = ({ auth }) => ({
  auth: auth
})



export default connect(
  mapStateToProps,
  null
)(App)
