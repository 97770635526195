import React from 'react'
import * as API from '../../../services/accounts';
import {connect} from 'react-redux'
import { siteUrl } from '../../../services/baseService';
import CloseCircleOutlined from '@ant-design/icons/CloseCircleOutlined'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import Button from 'antd/lib/button'
import Upload from 'antd/lib/upload'
import UploadOutlined from '@ant-design/icons/UploadOutlined'
import _ from 'lodash'
class Index extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            documents:[],
            selected_channel:false
        }
    }
    componentDidMount()
    {
      
      this.setData();
    }
    componentDidUpdate()
    {
      this.setData();
    }
    setData()
    {
      if(this.props.selected_channel !== this.state.selected_channel)
      {
        let self=this;
        self.state.selected_channel=this.props.selected_channel;
        this.LoadData();
      }
    }
    LoadData()
    {
      API.message_documents({channel_url:this.props.selected_channel.url}).then((res)=>{
        this.setState({documents:res.data.documents,uploading:false});
      })
    }

    DeleteFile(d)
    {
      this.setState({deleting:d.md5id})
      API.chat_files(d.md5id,localStorage.getItem('user_token')).then((res)=>{
        this.setState({documents:_.filter(this.state.documents,(a)=>a.md5id!==d.md5id),deleting:false})
      })
    }
  render() {
    
    return (
      <div>
          <div className="title_block">Shared Files</div>
          <div>
          <Upload name="files"  action={"https://lawtutors.co.uk/ajaxs/angular_document_upload?channel_url="+this.props.selected_channel.url} data={{user_id:localStorage.getItem('Id')}}
            showUploadList={false}
            
            onChange={(info)=>{
                this.setState({uploading:true})
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                  }
                  if (info.file.status === 'done') {
                    this.LoadData()
                  } else if (info.file.status === 'error') {
                    alert(`${info.file.name} file upload failed.`);
                  }
            }}
        
            
            >
                <Button id="uploadfilebtn" loading={this.state.uploading} icon={<UploadOutlined />} type="primary" shape="circle" className="m-1" />
            </Upload>
            </div>
              <div style={{height:'calc(50vh - 104px)',overflowY:'auto'}}>
                {this.state.documents.map((d)=><div className="py-1 px-2 d-flex">
                  <div style={{flex:1}}>
                  <a target="_blank" rel="noopener noreferrer" href={`${siteUrl}doc/${d.file_name}`}>{d.file_name}</a>
                  </div>
                  <div>{this.state.deleting===d.md5id?<LoadingOutlined loop={true} />:<CloseCircleOutlined style={{color:'red',cursor:'pointer'}} onClick={()=>this.DeleteFile(d)} />}</div>
                </div>)}
                </div>
            </div>
    );
  }
}




const mapStateToProps=(state)=>{
  return {
      selected_channel:state.sendbird.selected_channel
  }
}

export default connect(
  mapStateToProps,
  null
)(Index)
