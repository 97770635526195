import React from 'react'
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal'
import * as auth from '../../../services/auth';
import connect from 'react-redux/lib/connect/connect'
import Input from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading:false,
          invalid:false
        }
      }
  handleSubmit = (values) => {
    
    
        this.setState({
            loading: true
          })
        
        var data = {
            phone: values.username.replace(this.state.countrycode,""),
            country_code:this.state.countrycode
          };
          
         
          auth.phoneotp(data).then((response)=>{
            if(response.data.status===1)
            {
                  this.props.OTPSent(data.phone,data.country_code,response.data.message);
            }
            else
            {
                this.setState({invalid:true,msg:response.data.message,loading:false});
            }
          })
          .catch((error)=>{
            this.setState({
              loading:false
            })
            Modal.error({
              title: 'Network Error',
              content: 'something went wrong..',
            });
          })

  };

  render() {
    return (
      <Form onFinish={this.handleSubmit} className="login-form" initialValues={{username:'+44'}}>
        
        <div style={{fontSize:18,marginTop:20,textAlign:"center",paddingBottom:10}}>Enter the phone number you signed up with to receive your login code via sms </div>
          {this.state.invalid && <div className="text-center" style={{color:'red',marginBottom:10}}>{this.state.msg}</div>}
          <Form.Item style={{margin:0,padding:0}} name="username" rules={[{ required: true, message: 'Enter Phone'}]}>
            
              <Input 
              onChange={(e,c)=>{  this.setState({phone:e,countrycode:c.dialCode}) }}
                placeholder="Mobile Number"
                
              />
          </Form.Item>
        <Form.Item>
          
           
            <Button htmlType="submit" className="blooms-btn mt-3" style={{width:'100%'}} loading={this.state.loading}>
            Send Code
          </Button>  
          
          
          
        </Form.Item>
        <div className="mt-5 text-center">© 2019-2020</div>
      </Form>
    );
  }
}




const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      loggedIn: (data) => {
          dispatch({ type: "USER_LOGIN", payload:data });
      }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
