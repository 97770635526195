import React from 'react'
import Modal from 'antd/lib/modal'
import * as API from '../../services/accounts';
import Table from 'antd/lib/table'
import SideMenu from '../app/components/SideMenu';
import { connect } from 'react-redux'
import PaymentDetail from './components/PaymentDetail'
import Spin from 'antd/lib/spin'
class MyAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      records:[],
      selectedRecord:false
    }
    
  }
  componentDidMount()
  {
    this.loadData();
    
  }
  loadData()
  {
    this.setState({loading:true })
    API.student_history({"filter":[]}).then((response)=>{
      if(response.data.status===1)
      {
        this.setState({ records:response.data.history,loading:false,payment_method:response.data.payment_method ,available_balance:response.data.available_balance,remaining_credit_balance:response.data.remaining_credit_balance});
      }
      else
      {
        this.setState({  message:response.data.message,loading:false});
      }

    })
    .catch((error)=>{
      this.setState({loading:false})
      Modal.error({
        title: 'Network Error',
        content: 'something went wrong..',
      });
    })
  }
  
  render()
  {
    const columns = [
      {
        title: 'Item',
        dataIndex: 'lesson_date',
        key: 'lesson_date',
        sorter: false,
        render: (val,record)=><div title={`${record.detail.lesson_title}: ${val}`} className="text_overlap">{record.detail.lesson_title}: {val}</div>
      },
      {
        title: 'Processed',
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      },
      {
        title: 'Tutor',
        dataIndex: 'tutor',
        key: 'tutor',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      },
      {
        title: 'Price',
        dataIndex: 'price',
        key: 'price',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">&pound;{val}</div>
      },
      {
        title: 'Method',
        dataIndex: 'payment_method',
        key: 'payment_method',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      }
    ];

    const columns2 = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      },
      {
        title: 'Type',
        dataIndex: 'transaction_type',
        key: 'transaction_type',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val==='1'?'Deposite':'Payment'}</div>
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val===''?<span>--</span>:<span>&pound;{val}</span>}</div>
      },
      {
        title: 'Ref',
        dataIndex: 'ref',
        key: 're',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val}</div>
      },
      {
        title: 'Credit',
        dataIndex: 'credit',
        key: 'credit',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val===''?<span>--</span>:<span>&pound;{val}</span>}</div>
      },
      {
        title: 'Debit',
        dataIndex: 'debit',
        key: 'debit',
        sorter: false,
        render: (val)=><div title={val} className="text_overlap">{val===''?<span>--</span>:<span>&pound;{val}</span>}</div>
      },
      {
        title: 'Balance',
        dataIndex: 'balance',
        key: 'balance',
        sorter: false,
        render: (val,record)=><div title={val} className="text_overlap">{record.plusminus}&pound;{val}</div>
      }
    ];
    
    return (<div className="container mt-5 mb-5">
      {this.state.selectedRecord && <PaymentDetail detail={this.state.selectedRecord} onClose={()=>this.setState({selectedRecord:false})} />}
      <div className="row">
          <div className="col-md-3">
           <SideMenu menu="4" title="History" />
          </div>
          <div className="col-md-9">
            {this.state.payment_method==='2' && <div className="p-3" style={{maxWidth:350,backgroundColor:'white',borderRadius:10}}>
                <div style={{fontSize:24}}>Available Balance: &pound;{this.state.available_balance}</div>
                <div style={{fontSize:18}}>(&pound;{this.state.remaining_credit_balance} available)</div>
                <div style={{fontSize:12,marginTop:20}}>This is your active payment method.</div>
              </div>}
            <Spin spinning={this.state.loading}>
              
            <Table 
              className="mt-2"
              onRow={(record, index) => {
                return {
                  onClick: event => { this.setState({ selectedRecord: record });  },
                };
              }}
              columns={this.state.payment_method==='2'?columns2:columns}
              dataSource={this.state.records}
              pagination={{hideOnSinglePage:true,defaultPageSize:10,pageSizeOptions:['10','50','100'], showTotal:(total,range) => `${range[0]} to ${range[1]} of ${total} patients`,showSizeChanger:true}}
            />
            </Spin>
          </div>
      </div>
      
      
    </div>);
  }
  
}
const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount)
