import React from 'react'
import * as sendbird from './sendbird'
import connect from 'react-redux/lib/connect/connect'

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_channel:null
    }
    
  }
  componentDidMount()
  {
    if(sendbird.getSB().currentUser===null)
    {
         
        sendbird.connect().then(()=>{
            this.LoadChannels();
        })
    }
    else
    {
        this.LoadChannels();
    }
    this.notifyMe();
  }
  componentDidUpdate()
  {
    if(this.props.selected_channel!==this.state.selected_channel)
    {
      this.LoadMessages();
      this.setState({selected_channel:this.props.selected_channel})
    }
    if(this.props.sendMessage!=='')
    {
      sendbird.sendMessage(this.props.selected_channel,this.props.sendMessage).then((message)=>{
        this.props.MessageAdd({channel:this.props.selected_channel, message});
      });
      this.props.MessageSent();
    }
  }
  notifyMe() {
    if (window.Notification && Notification.permission === "granted") {
      console.log("Notification is already granted.");
    } else if (window.Notification && Notification.permission !== "denied") {
      Notification.requestPermission(function (status) {
        if (Notification.permission !== status) {
          Notification.permission = status;
        }
  
        if (status === "granted") {
          console.log("Notification is granted.");
        } else {
          console.log("Notification is denied.");
        }
      });
    }
  }
  
  notifyMessage(title,message) {
    if (window.Notification && Notification.permission === "granted")
    {
      var iconUrl = 'https://lawtutors.co.uk/theme/image/logo.png';
      
      if (window.Notification && Notification.permission === "granted") {
        //var noti = new Notification("Boomsbury | " + channel.url, {
      var noti = new Notification(title, {
          icon: iconUrl,
          body: message,
          tag: "tag"
        });
        noti.onclick = function(){
          window.focus();
        }
      }
    }
  }
  LoadMessages()
  {
    sendbird.messageList(this.props.selected_channel).then((messages)=>{
      this.props.MessageList(messages);
    })
  }
  LoadChannels()
  {
    let sb = sendbird.getSB();
    var channelHandler = new sb.ChannelHandler();
    channelHandler.onMessageReceived = (channel, message) =>{
      if(this.props.selected_channel!==null)  
      {
        if(this.props.selected_channel.url===channel.url)
            channel.markAsRead();
        this.props.MessageAdd({channel, message});
      }
      
      
      if(!document.hasFocus())
      {
        let name = "Admin";
        if(message._sender)
          name = message._sender.nickname;
        
        this.notifyMessage("Bloomsbury - " + name,message.message);
      }
    };
    channelHandler.onChannelChanged = (channel)=> {
        this.props.UpdateChannel(channel);
    };
    sb.addChannelHandler("1",channelHandler);
    sendbird.channelList().then((list)=>{
        this.props.ChannelList(list);
    });
  }
  render()
  {
    
    return (<span />);
  }
  
}


const mapStateToProps=(state)=>{
  return {
      auth:state.auth,
      selected_channel:state.sendbird.selected_channel,
      sendMessage:state.sendbird.sendMessage
  }
}
const mapDispatchToProps = dispatch => {
  return {
      ChannelList: (data) => {
          dispatch({ type: "CHANNEL_LIST", payload:data });
      },
      UpdateChannel: (data) => {
        dispatch({ type: "CHANNEL_UPDATE", payload:data });
      },
      MessageList: (data) => {
        dispatch({ type: "MESSAGE_LIST", payload:data });
      },
      MessageAdd: (data) => {
        dispatch({ type: "MESSAGE_ADD", payload:data });
      },
      MessageSent: () => {
        dispatch({ type: "MESSAGE_SEND", payload:'' });
      }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
