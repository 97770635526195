import React from 'react'
import '../../fullcalendar.min.css'
import '../../customcalendar.css'
import Modal from 'antd/lib/modal'
import * as accounts from '../../services/accounts';
import SideMenu from '../app/components/SideMenu';

class Notification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    
    }
    
  }
  
  loadData()
  {
         this.setState({loading:true })
            accounts.getevents({from:this.state.from,to:this.state.to}).then((response)=>{
              this.setState({loading:false,events:response.data })
            })
            .catch((error)=>{
              this.setState({loading:false})
              Modal.error({
                title: 'Network Error',
                content: 'something went wrong..',
              });
            })
            
  }
  render()
  {
    
    return (<div className="container mt-5 mb-5">
      <div className="row">
          <div className="col-3">
           <SideMenu menu="5" title="Notification" />
          </div>
          <div className="col-9">
          Notification
          </div>
      </div>
      
      
    </div>);
  }
  
}

export default Notification
