import axios from 'axios';
export const baseUrl = 'https://new.lawtutors.co.uk/api';
//export const baseUrl = 'https://new.lawtutors.co.uk/devapi';
//export const baseUrl = 'http://localhost/manager/index.php/';
export const siteUrl = 'https://lawtutors.co.uk/';
const baseService = axios.create({
    baseURL: baseUrl,
    rejectUnauthorized: false
});

baseService.addAuthToken = () => {
    
    baseService.defaults.headers.common['Authorization'] = `${localStorage.getItem('authToken')}`;
};

baseService.removeAuthToken = () => {
    localStorage.clear();
    baseService.defaults.headers.common['Authorization'] = '';
    window.location.href="/";
};

baseService.interceptors.response.use((config) => {
    return config;
}, (error) => {
   
    if (error.response.status === 401) {
        baseService.removeAuthToken();
    }
    return window.Promise.reject(error);
});

if (localStorage.getItem('authToken')) {
    baseService.addAuthToken();
}

export default baseService;